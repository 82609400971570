import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  InputAdornment,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ProductFacilities from "./ProductFacilities";
import AppError from "../ErrorBoundaries/AppError";
import FormSelect from "../sharedComponents/selectors/FormSelect";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import useAddProduct from "../../customHooks/products/useAddProduct";
import useUpdateProduct from "../../customHooks/products/useUpdateProduct";
import useRemoveContainerDepositsByProductID from "../../customHooks/supplierProductDetails/useRemoveContainerDepositsByProductID";
import useEmailSendProductRequest from "../../customHooks/email/useEmailSendProductRequest";
import useUpdateProductApproveProduct from "../../customHooks/products/useUpdateProductApproveProduct";
import { containerTypes, productTypes } from "../../globalConstants";
import { FormSwitch } from "../sharedComponents";
import calculateProductPrice from "../../sharedFunctions/purchasingFunctions/calculateProductPrice";
import useExchangeRates from "../../customHooks/exchangeRates/useExchangeRates";
import moment from "moment";
import useEquipmentNames from "../../customHooks/products/useEquipmentNames";
import useJanitorialSupplyNames from "../../customHooks/products/useJanitorialSupplyNames";
import useProductChemicalNames from "../../customHooks/products/useProductChemicalNames";
import useTestingMaterialsSupplyNames from "../../customHooks/products/useTestingMaterialsSupplyNames";
import useFilterNames from "../../customHooks/products/useFilterNames";
import useMembraneNames from "../../customHooks/products/useMembraneNames";
import usePartNames from "../../customHooks/products/usePartNames";
import CustomDatePicker from "../sharedComponents/datePicker/CustomDatePicker";
import useManufacturerProductDetailsByProduct from "../../customHooks/manufacturerProductDetails/useManufacturerProductDetailsByProduct";
import ManufacturerProductDetails from "./ManufacturerProductDetails";
import useCopyProduct from "../../customHooks/products/useCopyProduct";
import useSupplierProductDetailsByProduct from "../../customHooks/supplierProductDetails/useSupplierProductDetailsByProduct";
import { checkIfEquipmentSupplies } from "../../sharedFunctions/checkIfEquipmentSupplies";
import ProductMaintenanceSection from "../maintenanceProcedures/maintenanceSection/ProductMaintenanceSection";

const ProductForm = ({
  close,
  editMode,
  isRequestProduct,
  product,
  supplierName,
}) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const { user } = useAuth0();
  const { exchangeRates, isLoading: isLoadingExchangeRates } =
    useExchangeRates();
  const {
    data: manufacturerProductDetails,
    isLoading: isLoadingManufacturerProductDetails,
  } = useManufacturerProductDetailsByProduct(product?._id);
  const {
    data: supplierProductDetails,
    isLoading: isLoadingSupplierProductDetails,
  } = useSupplierProductDetailsByProduct(product?._id);

  const [additionalDetails, setAdditionalDetails] = useState("");
  const [chemicalType, setChemicalType] = useState(product?.chemicalType ?? "");
  const [concentration, setConcentration] = useState(
    isNaN(product?.concentration * 100) ? "" : product?.concentration * 100
  );
  const [containerSize, setContainerSize] = useState(
    product?.containerSize ?? ""
  );
  const [containerSizeUnit, setContainerSizeUnit] = useState(
    product?.containerSizeUnit ?? ""
  );
  const [containerType, setContainerType] = useState(
    product?.containerType ?? ""
  );
  const [controlledPrecursor, setControlledPrecursor] = useState(
    product?.controlledPrecursor ?? false
  );
  const [diameter, setDiameter] = useState(product?.diameter ?? "");
  const [equipmentType, setEquipmentType] = useState(
    product?.equipmentType ?? ""
  );
  const [equivalents, setEquivalents] = useState(product?.equivalents ?? []);
  const [filterEnds, setFilterEnds] = useState(product?.filterEnds ?? "");
  const [filterMaterial, setFilterMaterial] = useState(
    product?.filterMaterial ?? ""
  );
  const [filterIsReusable, setFilterIsReusable] = useState(
    product?.filterIsReusable ?? false
  );
  const [filterRating, setFilterRating] = useState(product?.filterRating ?? "");
  const [filterType, setFilterType] = useState(product?.filterType ?? "");
  const [hasPST, setHasPST] = useState(
    product?.hasPST ?? checkIfEquipmentSupplies(product?.type) ? true : false
  );
  const [hazardous, setHazardous] = useState(product?.hazardous ?? false);
  const [hazardousClass, setHazardousClass] = useState(
    product?.hazardousClass ?? ""
  );
  const [harmonizedTariffScheduleCode, setHarmonizedTariffScheduleCode] =
    useState(product?.harmonizedTariffScheduleCode ?? "");
  const [isCopy, setIsCopy] = useState(false);
  const [janitorialSuppliesType, setJanitorialSuppliesType] = useState(
    product?.janitorialSuppliesType ?? ""
  );
  const [length, setLength] = useState(product?.length ?? "");
  const [membraneType, setMembraneType] = useState(product?.membraneType ?? "");
  const [name, setName] = useState(product?.name ?? "");
  const [NSFType, setNSFType] = useState(product?.NSFType ?? "");
  const [NSF61Approval, setNSF61Approval] = useState(
    product?.NSF61Approval ?? false
  );
  const [obsoleteDate, setObsoleteDate] = useState(
    product?.obsoleteDate ? moment(product?.obsoleteDate) : null
  );
  const [packageGroup, setPackageGroup] = useState(product?.packageGroup ?? "");
  const [parts, setParts] = useState(product?.parts ?? []);
  const [pinningThreshold, setPinningThreshold] = useState(
    product?.pinningThreshold ?? ""
  );
  const [pumpFields, setPumpFields] = useState(
    product?.pumpFields ?? {
      adjustment: "",
      chemicalFeedRateSetting: "",
      maxPumpFlowRateUnit: "",
      maxPumpFlowRate: "",
      maxPumpSetting: "",
      pumpSettingIncrement: "",
      pumpSettingUnits: [],
      turndownRatio: "",
    }
  );
  const [returnableContainer, setReturnableContainer] = useState(
    product?.returnableContainer ?? false
  );
  const [specificGravity, setSpecificGravity] = useState(
    product?.specificGravity ?? ""
  );
  const [saving, setSaving] = useState(false);
  const [shippingInstructions, setShippingInstructions] = useState(
    product?.shippingInstructions ?? ""
  );
  const [shippingName, setShippingName] = useState(product?.shippingName ?? "");
  const [submitError, setSubmitError] = useState(false);
  const [type, setType] = useState(product?.type ?? "");
  const [UNNumber, setUNNumber] = useState(product?.UNNumber ?? "");
  const [valid, setValid] = useState(false);

  const { data: chemicalProducts, isLoading: isLoadingChemicalProducts } =
    useProductChemicalNames(type === "Chemical");
  const {
    data: testingMaterialProducts,
    isLoading: isLoadingTestingMaterialProducts,
  } = useTestingMaterialsSupplyNames(
    type === "Testing and Calibration Materials"
  );
  const { data: equipmentProducts, isLoading: isLoadingEquipmentProducts } =
    useEquipmentNames(type === "Equipment");
  const { data: filterProducts, isLoading: isLoadingFilterProducts } =
    useFilterNames(type === "Filter");
  const { data: membraneProducts, isLoading: isLoadingMembraneProducts } =
    useMembraneNames(
      type === "Hollow Fiber Membrane" || type === "Spiral Wound Membrane"
    );
  const { data: partProducts, isLoading: isLoadingPartProducts } = usePartNames(
    type === "Part" || type === "Equipment"
  );
  const {
    data: janitorialSupplyProducts,
    isLoading: isLoadingJanitorialSupplyProducts,
  } = useJanitorialSupplyNames(type === "Janitorial Supplies");
  const addProduct = useAddProduct();
  const copyProduct = useCopyProduct();
  const emailSendProductRequest = useEmailSendProductRequest();
  const updateProduct = useUpdateProduct();
  const removeContainerDepositsByProductID =
    useRemoveContainerDepositsByProductID();
  const updateProductApproveProduct = useUpdateProductApproveProduct();

  const isLoadingProducts =
    isLoadingChemicalProducts ||
    isLoadingTestingMaterialProducts ||
    isLoadingEquipmentProducts ||
    isLoadingFilterProducts ||
    isLoadingMembraneProducts ||
    isLoadingPartProducts ||
    isLoadingJanitorialSupplyProducts;

  useEffect(() => {
    if (type === "") return setValid(false);
    if (
      (type === "Chemical" ||
        type === "Hollow Fiber Membrane" ||
        type === "Spiral Wound Membrane" ||
        type === "Part" ||
        type === "Equipment" ||
        type === "Testing and Calibration Materials" ||
        type === "Other") &&
      name === ""
    )
      return setValid(false);

    if (type === "Hollow Fiber Membrane" && pinningThreshold === "")
      return setValid(false);

    if (
      type === "Filter" &&
      (filterType === "" ||
        filterMaterial === "" ||
        filterRating === "" ||
        diameter === "" ||
        length === "")
    )
      return setValid(false);
    if (type === "Container" && !containerType) return setValid(false);

    if (
      type === "Janitorial Supplies" &&
      (name === "" || janitorialSuppliesType === "")
    )
      return setValid(false);

    setValid(true);
  }, [
    containerType,
    diameter,
    filterEnds,
    filterMaterial,
    filterRating,
    filterType,
    janitorialSuppliesType,
    length,
    name,
    pinningThreshold,
    type,
    pumpFields,
    equipmentType,
  ]);

  const productOptions = React.useMemo(() => {
    if (
      isLoadingChemicalProducts ||
      isLoadingTestingMaterialProducts ||
      isLoadingEquipmentProducts ||
      isLoadingFilterProducts ||
      isLoadingMembraneProducts ||
      isLoadingPartProducts ||
      isLoadingJanitorialSupplyProducts
    ) {
      return [];
    }

    switch (type) {
      case "Chemical":
        return chemicalProducts;
      case "Testing and Calibration Materials":
        return testingMaterialProducts;
      case "Equipment":
        return equipmentProducts;
      case "Filter":
        return filterProducts;
      case "Hollow Fiber Membrane":
      case "Spiral Wound Membrane":
        return membraneProducts;
      case "Part":
        return partProducts;
      case "Janitorial Supplies":
        return janitorialSupplyProducts;
      default:
        return [];
    }
  }, [
    type,
    chemicalProducts,
    testingMaterialProducts,
    equipmentProducts,
    filterProducts,
    membraneProducts,
    partProducts,
    janitorialSupplyProducts,
    isLoadingChemicalProducts,
    isLoadingTestingMaterialProducts,
    isLoadingEquipmentProducts,
    isLoadingFilterProducts,
    isLoadingMembraneProducts,
    isLoadingPartProducts,
    isLoadingJanitorialSupplyProducts,
  ]);

  const resetFields = (type) => {
    setChemicalType("");
    setConcentration("");
    setContainerType("");
    setControlledPrecursor(false);
    setDiameter("");
    setEquipmentType("");
    setEquivalents([]);
    setFilterEnds("");
    setFilterMaterial("");
    setFilterIsReusable(false);
    setFilterRating("");
    setFilterType("");
    setHasPST(checkIfEquipmentSupplies(type) ? true : false);
    setHazardous(false);
    setHazardousClass("");
    setHarmonizedTariffScheduleCode("");
    setJanitorialSuppliesType("");
    setLength("");
    setMembraneType("");
    setName("");
    setNSFType("");
    setNSF61Approval(false);
    setObsoleteDate(null);
    setPackageGroup("");
    setParts([]);
    setPinningThreshold("");
    setReturnableContainer(false);
    setSpecificGravity("");
    setShippingInstructions("");
    setShippingName("");
    setUNNumber("");
    setPumpFields({
      adjustment: "",
      chemicalFeedRateSetting: "",
      maxPumpFlowRateUnit: "",
      maxPumpFlowRate: "",
      maxPumpSetting: "",
      pumpSettingIncrement: "",
      pumpSettingUnits: [],
      turndownRatio: "",
    });
  };

  const onSubmitApproveProduct = async () => {
    try {
      setSaving(true);

      await updateProductApproveProduct.mutateAsync({
        productID: product._id,
      });

      setSaving(false);
      close();
    } catch (error) {
      setSubmitError(true);
      setSaving(false);
    }
  };

  const onSubmit = async () => {
    try {
      setSaving(true);
      let body = {
        harmonizedTariffScheduleCode,
        hasPST,
        type,
        equivalents,
        obsoleteDate,
      };

      if (type === "Chemical") {
        body = {
          ...body,
          name,
          chemicalType,
          concentration: concentration / 100 || null,
          containerType,
          controlledPrecursor,
          hazardous,
          hazardousClass,
          NSFType,
          NSF61Approval,
          packageGroup,
          shippingInstructions,
          shippingName,
          specificGravity,
          returnableContainer,
          UNNumber,
        };
      }

      if (isRequestProduct) {
        await emailSendProductRequest.mutateAsync({
          supplierName,
          productDetails: `${type} - ${name} - ${additionalDetails}`,
          userEmail: user.email,
        });

        body = {
          ...body,
          needsApproval: true,
        };
      }

      if (type === "Hollow Fiber Membrane" || type === "Spiral Wound Membrane")
        body = {
          ...body,
          diameter,
          name,
          membraneType,
        };

      if (type === "Hollow Fiber Membrane")
        body = {
          ...body,
          pinningThreshold,
        };

      if (type === "Filter")
        body = {
          ...body,
          filterType,
          filterEnds,
          filterIsReusable,
          filterMaterial,
          filterRating,
          diameter,
          length,
        };

      if (type === "Container")
        body = {
          ...body,

          containerType,
        };

      if (type === "Part")
        body = {
          ...body,
          name,
        };

      if (type === "Equipment")
        body = {
          ...body,
          equipmentType,
          name,
          parts,
        };

      if (type === "Equipment" && equipmentType.includes("Pump"))
        body = {
          ...body,
          pumpFields,
        };

      if (type === "Testing and Calibration Materials")
        body = {
          ...body,
          containerSize,
          containerSizeUnit,
          name,
        };

      if (type === "Other") {
        body = {
          ...body,
          name,
        };
      }

      if (type === "Janitorial Supplies")
        body = {
          ...body,
          janitorialSuppliesType,
          name,
        };

      if (product && !isCopy) {
        body._id = product._id;

        await updateProduct.mutateAsync({ body });
      } else if (isCopy) {
        await copyProduct.mutateAsync({ body });
      } else {
        await addProduct.mutateAsync({ body });
      }

      if (
        returnableContainer === false &&
        product?.returnableContainer === true
      ) {
        removeContainerDepositsByProductID.mutate(product._id);
      }

      setSaving(false);
      close();
    } catch (error) {
      setSubmitError(true);
      setSaving(false);
      throw error;
    }
  };

  const renderChemicalFields = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            required
            label=" Name"
            value={name}
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="containerType">Container Type</InputLabel>
            <Select
              required
              value={containerType}
              labelId="containerType"
              label="Container Type"
              onChange={(e) => {
                setContainerType(e.target.value);
              }}
              disabled={!editMode}
            >
              {containerTypes.map((containerType) => (
                <MenuItem key={containerType} value={containerType}>
                  {containerType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            autoComplete="off"
            label="Concentration"
            value={concentration}
            variant="outlined"
            InputProps={{
              inputMode: "decimal",
              pattern: "[0-9]*",
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={(e) => {
              setConcentration(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <TextField
            autoComplete="off"
            label="Specific Gravity"
            value={specificGravity}
            variant="outlined"
            inputProps={{ inputMode: "decimal", pattern: "[0-9]*" }}
            onChange={(e) => {
              setSpecificGravity(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
        <FormSwitch
          disabled={!editMode}
          checked={returnableContainer}
          onChange={(e) => setReturnableContainer(e.target.checked)}
          label="Returnable Container"
          xs={6}
          md={3}
        />

        <FormSwitch
          disabled={!editMode}
          checked={NSF61Approval}
          onChange={(e) => setNSF61Approval(e.target.checked)}
          label="NSF"
          xs={6}
          md={3}
        />
        <FormSwitch
          disabled={!editMode}
          checked={hazardous}
          onChange={(e) => setHazardous(e.target.checked)}
          label="Hazardous"
          xs={6}
          md={3}
        />
        <FormSwitch
          disabled={!editMode}
          checked={controlledPrecursor}
          onChange={(e) => setControlledPrecursor(e.target.checked)}
          label="Controlled Precursor"
          xs={6}
          md={3}
        />

        {NSF61Approval && (
          <Grid item xs={6} md={3}>
            <TextField
              autoComplete="off"
              label="NSF Type"
              disabled={!editMode}
              value={NSFType}
              variant="outlined"
              onChange={(e) => {
                setNSFType(e.target.value);
              }}
              fullWidth
            />
          </Grid>
        )}
        {hazardous && (
          <>
            <Grid item xs={6} md={4}>
              <DecimalTextField
                editMode={editMode}
                value={UNNumber}
                onChange={(e) => setUNNumber(e.target.value)}
                label={"UN#"}
                required={false}
                allowMinus={false}
                allowDecimal={false}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="hazardousClass">Hazardous Class</InputLabel>
                <Select
                  required
                  value={hazardousClass}
                  labelId="hazardousClass"
                  label="Hazardous Class"
                  onChange={(e) => {
                    setHazardousClass(e.target.value);
                  }}
                  disabled={!editMode}
                >
                  <MenuItem value={"1.1"}>1.1</MenuItem>
                  <MenuItem value={"1.2"}>1.2</MenuItem>
                  <MenuItem value={"1.3"}>1.3</MenuItem>
                  <MenuItem value={"1.4"}>1.4</MenuItem>
                  <MenuItem value={"1.5"}>1.5</MenuItem>
                  <MenuItem value={"1.6"}>1.6</MenuItem>
                  <MenuItem value={"2.1"}>2.1</MenuItem>
                  <MenuItem value={"2.2"}>2.2</MenuItem>
                  <MenuItem value={"2.3"}>2.3</MenuItem>
                  <MenuItem value={"2.2(5.1)"}>2.2(5.1)</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"3(6.1)"}>3(6.1)</MenuItem>
                  <MenuItem value={"4.1"}>4.1</MenuItem>
                  <MenuItem value={"4.2"}>4.2</MenuItem>
                  <MenuItem value={"4.3"}>4.3</MenuItem>
                  <MenuItem value={"5.1"}>5.1</MenuItem>
                  <MenuItem value={"5.2"}>5.2</MenuItem>
                  <MenuItem value={"6.1"}>6.1</MenuItem>
                  <MenuItem value={"6.2"}>6.2</MenuItem>
                  <MenuItem value={"7"}>7</MenuItem>
                  <MenuItem value={"8"}>8</MenuItem>
                  <MenuItem value={"9"}>9</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="packageGroup">Package Group</InputLabel>
                <Select
                  required
                  value={packageGroup}
                  labelId="packageGroup"
                  label="Package Group"
                  onChange={(e) => {
                    setPackageGroup(e.target.value);
                  }}
                  disabled={!editMode}
                >
                  <MenuItem value={"I"}>I</MenuItem>
                  <MenuItem value={"II"}>II</MenuItem>
                  <MenuItem value={"III"}>III</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            label="Shipping Name"
            value={shippingName}
            variant="outlined"
            onChange={(e) => {
              setShippingName(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            label="Shipping Instructions"
            value={shippingInstructions}
            variant="outlined"
            onChange={(e) => {
              setShippingInstructions(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
            multiline
          />
        </Grid>
      </>
    );
  };

  const renderMembraneFields = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            required
            label="Name"
            value={name}
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="membraneTypeLabel">Membrane Type</InputLabel>
            <Select
              required
              value={membraneType}
              labelId="membraneTypeLabel"
              label="Membrane Type"
              onChange={(e) => {
                setMembraneType(e.target.value);
              }}
              disabled={!editMode}
            >
              {type === "Hollow Fiber Membrane" && (
                <MenuItem value={"MF"}>MF</MenuItem>
              )}
              {type === "Hollow Fiber Membrane" && (
                <MenuItem value={"UF"}>UF</MenuItem>
              )}
              {type === "Spiral Wound Membrane" && (
                <MenuItem value={"NF"}>NF</MenuItem>
              )}
              {type === "Spiral Wound Membrane" && (
                <MenuItem value={"RO"}>RO</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        {type === "Spiral Wound Membrane" && (
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="diameterLabel">Diameter</InputLabel>
              <Select
                required
                value={diameter}
                labelId="diameterLabel"
                label="Diameter"
                onChange={(e) => {
                  setDiameter(e.target.value);
                }}
                disabled={!editMode}
              >
                <MenuItem value={"2.5"}>2.5"</MenuItem>
                <MenuItem value={"4"}>4"</MenuItem>
                <MenuItem value={"8"}>8"</MenuItem>
                <MenuItem value={"16"}>16"</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {type === "Hollow Fiber Membrane" && (
          <Grid item xs={6} md={3}>
            <DecimalTextField
              editMode={editMode}
              value={pinningThreshold}
              onChange={(e) => setPinningThreshold(e.target.value)}
              label={"Pinning Threshold"}
              required={true}
              numberProps={{ min: 0 }}
            />
          </Grid>
        )}
      </>
    );
  };

  const renderFilterFields = () => {
    return (
      <>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="filterTypeLabel" required>
              Filter Type
            </InputLabel>
            <Select
              required
              value={filterType}
              labelId="filterTypeLabel"
              label="Filter Type"
              onChange={(e) => {
                setFilterType(e.target.value);
              }}
              disabled={!editMode}
            >
              <MenuItem value={"Bag"}>Bag</MenuItem>
              <MenuItem value={"Cartridge"}>Cartridge</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="filterMaterialLabel" required>
              Filter Material
            </InputLabel>
            <Select
              required
              value={filterMaterial}
              labelId="filterMaterialLabel"
              label="Filter Material"
              onChange={(e) => {
                setFilterMaterial(e.target.value);
              }}
              disabled={!editMode}
            >
              <MenuItem value={"Melt-Blown Polypropylene"}>
                Melt-Blown Polypropylene
              </MenuItem>
              <MenuItem value={"Pleated Polypropylene Depth Structure"}>
                Pleated Polypropylene Depth Structure
              </MenuItem>
              <MenuItem value={"Stainless Steel Mesh"}>
                Stainless Steel Mesh
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="filterEndsLabel" required>
              Filter Ends
            </InputLabel>
            <Select
              value={filterEnds}
              labelId="filterEndsLabel"
              label="Filter Ends"
              onChange={(e) => {
                setFilterEnds(e.target.value);
              }}
              disabled={!editMode}
            >
              <MenuItem value={"Cut-Ends"}>Cut-Ends</MenuItem>
              <MenuItem value={"Buna-N"}>Buna-N</MenuItem>
              <MenuItem value={"Ethylene Propylene O-ring"}>
                Ethylene Propylene O-ring
              </MenuItem>
              <MenuItem value={"226/Flat Single Open End"}>
                226/Flat Single Open End
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="filterRatingLabel" required>
              Filter Rating
            </InputLabel>
            <Select
              required
              value={filterRating}
              labelId="filterRatingLabel"
              label="Filter Rating"
              onChange={(e) => {
                setFilterRating(e.target.value);
              }}
              disabled={!editMode}
            >
              <MenuItem value={"1 micron"}>1 micron</MenuItem>
              <MenuItem value={"4.5 micron"}>4.5 micron</MenuItem>
              <MenuItem value={"5 micron"}>5 micron</MenuItem>
              <MenuItem value={"10 micron"}>10 micron</MenuItem>
              <MenuItem value={"25 micron"}>25 micron</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="diameterLabel" required>
              Diameter
            </InputLabel>
            <Select
              required
              value={diameter}
              labelId="diameterLabel"
              label="Diameter"
              onChange={(e) => {
                setDiameter(e.target.value);
              }}
              disabled={!editMode}
            >
              <MenuItem value={"2.5"}>2.5"</MenuItem>
              <MenuItem value={"4"}>4"</MenuItem>
              <MenuItem value={"4.5"}>4.5"</MenuItem>
              <MenuItem value={"6"}>6"</MenuItem>
              <MenuItem value={"6.5"}>6.5"</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="lengthLabel" required>
              Length
            </InputLabel>
            <Select
              required
              value={length}
              labelId="lengthLabel"
              label="Length"
              onChange={(e) => {
                setLength(e.target.value);
              }}
              disabled={!editMode}
            >
              <MenuItem value={"10"}>10"</MenuItem>
              <MenuItem value={"20"}>20"</MenuItem>
              <MenuItem value={"30"}>30"</MenuItem>
              <MenuItem value={"40"}>40"</MenuItem>
              <MenuItem value={"60"}>60"</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <FormSwitch
          disabled={!editMode}
          checked={filterIsReusable}
          onChange={(e) => setFilterIsReusable(e.target.checked)}
          label="Reusable"
          xs={6}
          md={3}
        />
      </>
    );
  };

  const renderContainerFields = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="containerType">Container Type</InputLabel>
            <Select
              required
              value={containerType}
              labelId="containerType"
              label="Container Type"
              onChange={(e) => {
                setContainerType(e.target.value);
              }}
              disabled={!editMode}
            >
              {containerTypes.map((containerType) => (
                <MenuItem key={containerType} value={containerType}>
                  {containerType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderPartFields = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            label="Name"
            value={name}
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
            required
          />
        </Grid>
      </>
    );
  };
  const renderEquipmentFields = () => {
    const mappedParts = parts.map((part, i) => (
      <React.Fragment key={i}>
        <Grid item xs={6} md={4}>
          <Autocomplete
            required
            options={[...partProducts, ...equipmentProducts]}
            getOptionLabel={(option) => option?.name ?? ""}
            value={part ?? ""}
            isOptionEqualToValue={(option, value) => {
              if (!value.length) return [];
              return option._id === value._id;
            }}
            onChange={(e, value) => {
              const tempParts = [...parts];
              tempParts[i] = value;
              setParts(tempParts);
            }}
            disabled={!editMode}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label="Part"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          {editMode && (
            <Button
              variant="contained"
              onClick={() => {
                const tempParts = [...parts];
                tempParts.splice(i, 1);
                setParts(tempParts);
              }}
              sx={{ mt: 1 }}
              fullWidth
            >
              Remove
            </Button>
          )}
        </Grid>
      </React.Fragment>
    ));
    return (
      <>
        <Grid item xs={12} md={6}>
          <FormSelect
            editMode={editMode}
            value={equipmentType}
            onChange={(e) => setEquipmentType(e.target.value)}
            menuItems={[
              "Air Compressor",
              "Air Blower",
              "Dosing Pump",
              "Filter Cartridge Housing",
              "Instrument",
              "Membrane Pressure Vessel",
              "Piping and Tubing",
              "Valve",
              "Water Pump",
            ]}
            label={"Equipment Type"}
            required={false}
          />
        </Grid>
        {renderPartFields()}
        {equipmentType.includes("Pump") && renderPumpFields()}
        <Grid item xs={12}>
          {editMode && (
            <Button
              variant="contained"
              onClick={() => setParts([...parts, ""])}
              fullWidth
            >
              Add New Part
            </Button>
          )}
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {mappedParts}
        </Grid>
      </>
    );
  };

  const renderPumpFields = () => {
    return (
      <>
        <Grid item xs={6} md={3}>
          <DecimalTextField
            editMode={editMode}
            value={pumpFields.maxPumpFlowRate}
            onChange={(e) =>
              setPumpFields({
                ...pumpFields,
                maxPumpFlowRate: e.target.value,
              })
            }
            label={"Max Pump Flow Rate"}
            required={false}
            numberProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormSelect
            editMode={editMode}
            value={pumpFields.maxPumpFlowRateUnit}
            onChange={(e) =>
              setPumpFields({
                ...pumpFields,
                maxPumpFlowRateUnit: e.target.value,
              })
            }
            menuItems={["L/h", "L/min", "L/s", "ml/h"]}
            label={"Max Pump Flow Rate Unit"}
            required={false}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DecimalTextField
            editMode={editMode}
            value={pumpFields.maxPumpSetting}
            onChange={(e) =>
              setPumpFields({
                ...pumpFields,
                maxPumpSetting: e.target.value,
              })
            }
            label={"Max Pump Setting"}
            required={false}
            numberProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id={`Pump Setting Units`}>
              Pump Setting Units
            </InputLabel>
            <Select
              multiple
              disabled={!editMode}
              value={pumpFields.pumpSettingUnits}
              labelId={`Pump Setting Units`}
              label={`Pump Setting Units`}
              onChange={(e) => {
                setPumpFields({
                  ...pumpFields,
                  pumpSettingUnits: e.target.value,
                });
              }}
            >
              {[
                "%",
                "L/h",
                "L/s",
                "ml/h",
                "ml/m3",
                "gpd",
                "L/min",
                "gal/min",
              ]?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <DecimalTextField
            editMode={editMode}
            value={pumpFields.adjustment}
            onChange={(e) =>
              setPumpFields({
                ...pumpFields,
                adjustment: e.target.value,
              })
            }
            label={"Adjustment"}
            required={false}
            numberProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormSelect
            editMode={editMode}
            value={pumpFields.chemicalFeedRateSetting}
            onChange={(e) =>
              setPumpFields({
                ...pumpFields,
                chemicalFeedRateSetting: e.target.value,
              })
            }
            menuItems={["dial", "pump screen", "HMI"]}
            label={"Chemical Feed Rate Setting"}
            required={false}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DecimalTextField
            editMode={editMode}
            value={pumpFields.pumpSettingIncrement}
            onChange={(e) =>
              setPumpFields({
                ...pumpFields,
                pumpSettingIncrement: e.target.value,
              })
            }
            label={"Pump Setting Increment"}
            required={false}
            numberProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DecimalTextField
            editMode={editMode}
            value={pumpFields.turndownRatio}
            onChange={(e) =>
              setPumpFields({
                ...pumpFields,
                turndownRatio: e.target.value,
              })
            }
            label={"Turndown Ratio"}
            required={false}
            numberProps={{ min: 0 }}
          />
        </Grid>
      </>
    );
  };

  const renderTestingMaterialsFields = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            required
            label="Name"
            value={name}
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DecimalTextField
            editMode={editMode}
            value={containerSize}
            onChange={(e) => setContainerSize(e.target.value)}
            label={"Container Size"}
            required={false}
            numberProps={{ min: 0 }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormSelect
            editMode={editMode}
            value={containerSizeUnit}
            onChange={(e) => setContainerSizeUnit(e.target.value)}
            menuItems={["kg", "lb", "ml", "L", "gal", "PK"]}
            label={"Container Size Unit"}
            required={false}
          />
        </Grid>
      </>
    );
  };
  const renderSuppliers = () => {
    const suppliers = supplierProductDetails?.map((supplierProductDetail) => {
      return {
        supplier: supplierProductDetail.supplier ?? [],
        cost: supplierProductDetail.cost ?? "",
        price:
          calculateProductPrice(
            supplierProductDetail.cost,
            exchangeRates.find(
              (exchangeRate) =>
                exchangeRate.currency ===
                supplierProductDetail.supplier.currency
            )?.rate,
            supplierProductDetail.product.type === "Container"
              ? null
              : supplierProductDetail.supplier._id
          ) ?? "",
      };
    });
    if (!suppliers?.length) return;
    if (isLoadingExchangeRates || isLoadingSupplierProductDetails)
      return (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      );
    return (
      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Suppliers</Typography>
        </Grid>
        {suppliers.map((supplier, i) => {
          return (
            <Grid item xs={12} container spacing={2} key={"Supplier" + i}>
              <Grid item xs={12} md={6}>
                <Typography>{supplier.supplier.name}</Typography>
              </Grid>
              {supplier.cost > 0 && (
                <Grid item xs={4} md={2}>
                  <Typography>Cost: {supplier.cost}</Typography>
                </Grid>
              )}
              {supplier.price > 0 && (
                <Grid item xs={4} md={2}>
                  <Typography>Price: {supplier.price}</Typography>
                </Grid>
              )}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderContent = () => {
    return (
      <Grid container spacing={2}>
        {editMode && product && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setName("");

                setIsCopy(true);
              }}
              fullWidth
            >
              Copy To New Product
            </Button>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="typeLabel" required>
              Type
            </InputLabel>
            <Select
              required
              value={type}
              labelId="typeLabel"
              label="Type"
              onChange={(e) => {
                setType(e.target.value);
                resetFields(e.target.value);
              }}
              disabled={!editMode}
            >
              {productTypes.map((chemicalType) => (
                <MenuItem key={chemicalType} value={chemicalType}>
                  {chemicalType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {type === "Chemical" && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="chemicalTypeLabel">Chemical Type</InputLabel>
              <Select
                required
                value={chemicalType}
                labelId="chemicalTypeLabel"
                label="Chemical Type"
                onChange={(e) => {
                  setChemicalType(e.target.value);
                }}
                disabled={!editMode}
              >
                {[
                  "Antiscalant",
                  "Coagulant",
                  "Corrosion Inhibitor",
                  "Mineral",
                  "RO/NF Cleaning Chemical",
                  "UF/MF Cleaning Chemical",
                  "Other",
                ].map((chemicalType) => (
                  <MenuItem key={chemicalType} value={chemicalType}>
                    {chemicalType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {type === "Janitorial Supplies" && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="janitorialSuppliesType">Type</InputLabel>
                <Select
                  required
                  value={janitorialSuppliesType}
                  labelId="janitorialSuppliesType"
                  label="Type"
                  onChange={(e) => {
                    setJanitorialSuppliesType(e.target.value);
                  }}
                  disabled={!editMode}
                >
                  {[
                    "Chemicals",
                    "Cleaning Accessories",
                    "Cleaning Tools",
                    "Facility Essentials",
                    "Other",
                  ].map((janitorialSuppliesType) => (
                    <MenuItem
                      key={janitorialSuppliesType}
                      value={janitorialSuppliesType}
                    >
                      {janitorialSuppliesType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                label="Name"
                value={name}
                variant="outlined"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                disabled={!editMode}
                fullWidth
                required
              />
            </Grid>
          </>
        )}
        {type === "Chemical" && renderChemicalFields()}
        {type === "Container" && renderContainerFields()}
        {type === "Equipment" && renderEquipmentFields()}
        {type === "Filter" && renderFilterFields()}
        {type === "Part" && renderPartFields()}
        {(type === "Hollow Fiber Membrane" ||
          type === "Spiral Wound Membrane") &&
          renderMembraneFields()}
        {type === "Testing and Calibration Materials" &&
          renderTestingMaterialsFields()}
        {type === "Other" && (
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              required
              label="Description"
              value={name}
              variant="outlined"
              onChange={(e) => {
                setName(e.target.value);
              }}
              multiline
              disabled={!editMode}
              fullWidth
            />
          </Grid>
        )}
        {type !== "Container" && type !== "Other" && (
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="Equivalents"
              options={productOptions}
              getOptionLabel={(option) => option.name}
              loading={isLoadingProducts}
              disabled={!editMode}
              value={equivalents}
              isOptionEqualToValue={(option, value) => {
                if (!value.length) return [];
                return option?._id === value?._id;
              }}
              onChange={(e, value) => {
                setEquivalents(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Equivalents"
                  placeholder="Equivalents"
                />
              )}
            />
          </Grid>
        )}
        {type && (
          <>
            <Grid item xs={6} md={4}>
              <CustomDatePicker
                disabled={!editMode}
                label="Obsolete Date"
                onChange={setObsoleteDate}
                value={obsoleteDate}
              />
            </Grid>

            <FormSwitch
              disabled={!editMode}
              checked={hasPST}
              onChange={(e) => setHasPST(e.target.checked)}
              label="Has PST"
              xs={6}
              md={3}
            />
          </>
        )}
        <Grid item xs={6} md={4}>
          <TextField
            autoComplete="off"
            label="HTS Code"
            value={harmonizedTariffScheduleCode}
            variant="outlined"
            onChange={(e) => {
              setHarmonizedTariffScheduleCode(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
        {isRequestProduct && (
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              label="Additional Details"
              value={additionalDetails}
              variant="outlined"
              onChange={(e) => {
                setAdditionalDetails(e.target.value);
              }}
              fullWidth
            />
          </Grid>
        )}
        {editMode && (
          <>
            {appUserLevel.includes("Super User") && product?.needsApproval && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "column" }}
                className="centered-container"
              >
                <Button
                  variant="contained"
                  disabled={!valid || saving}
                  fullWidth
                  onClick={onSubmitApproveProduct}
                >
                  {saving ? "Approving Product" : "Approve Product"}
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column" }}
              className="centered-container"
            >
              {/* {errorMessage && (
              <Typography variant="h6" sx={{ color: "red" }}>
                {errorMessage}
              </Typography>
            )} */}

              <Button
                variant="contained"
                disabled={!valid || saving}
                fullWidth
                onClick={onSubmit}
              >
                {!isRequestProduct
                  ? product && !isCopy
                    ? saving
                      ? "Updating Product"
                      : "Update Product"
                    : saving
                    ? "Adding Product"
                    : "Add Product"
                  : isRequestProduct && saving
                  ? "Requesting Product"
                  : "Request Product"}
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <ManufacturerProductDetails
          editMode={editMode}
          manufacturerProductDetails={manufacturerProductDetails}
          product={product}
          isLoading={isLoadingManufacturerProductDetails}
        />

        {(appUserLevel?.includes("Super User") ||
          appUserLevel.includes("Super Product User")) &&
          type === "Equipment" &&
          product?._id && (
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Maintenance</Typography>
              </Grid>
              <ProductMaintenanceSection product={product} />
            </Grid>
          )}

        {product && renderSuppliers()}
        {product && <ProductFacilities product={product} />}
      </Grid>
    );
  };

  if (submitError)
    return (
      <AppError
        message="There was a problem submitting the Product."
        type="Product"
      />
    );
  return renderContent();
};

export default ProductForm;
