import { GST } from "../../globalConstants";
import calculateProductPrice from "./calculateProductPrice";
import calculateTotalProductAmount from "./calculateTotalProductAmount";
import { calculateTotalProductAmountWithExchange } from "./calculateTotalProductAmountWithExchange";
import customDollarRound from "./customDollarRound";
import { findTierPricingCost } from "./findTierPricingCost";
import getPSTRate from "./getPSTRate";

const getShippingCost = (productInCart) => {
  if (
    !productInCart?.closestWarehouse?.distance ||
    productInCart.supplierProductDetail.shippingIncluded
  )
    return 0;
  const distance = productInCart.closestWarehouse.distance / 1000;
  const { shippingRate } = productInCart.closestWarehouse;
  if (!shippingRate) return 0;
  const { weightCoefficient, distanceCoefficient, baseRate } =
    productInCart.closestWarehouse.shippingRate;
  if (!weightCoefficient || !distanceCoefficient || !baseRate) return 0;

  const weight =
    productInCart.supplierProductDetail.manufacturerProductDetail?.weight;
  const totalWeight = productInCart.quantity * weight;
  const predictedBaseCost =
    weightCoefficient * totalWeight + distanceCoefficient * distance;
  const finalShippingCost = predictedBaseCost * baseRate;
  return finalShippingCost;
};

const calculateOrderTotals = ({
  exchangeRates,
  facilityCredit,
  facilityHasGST,
  facilityHasPST,
  facilityProvince,
  productsInCartByWarehouse,
  supplierPSTExempt,
  warehouseAddress,
}) => {
  const facilityPST = getPSTRate(facilityProvince);
  const supplierPST = getPSTRate(warehouseAddress);

  let productTotal = 0;
  let PSTAmount = 0;
  let GSTAmount = 0;
  let credit = 0;
  let supplierSubtotal = 0;
  let supplierGSTAmount = 0;
  let supplierPSTAmount = 0;
  let shippingCost = 0;
  let deposits = 0;

  productsInCartByWarehouse.forEach((productInCartByWarehouse) => {
    let flatRateAddedForWarehouse = false;

    const productsInCartWithoutReturns =
      productInCartByWarehouse.products?.filter(
        (productInCart) => !productInCart.supplierProductDetail.isReturn
      );

    productsInCartWithoutReturns.forEach((productInCart, i) => {
      const productCost = findTierPricingCost(productInCart);
      const exchangeRate = exchangeRates.find(
        (exchangeRate) =>
          exchangeRate.currency ===
          productInCart.supplierProductDetail.supplier.currency
      )?.rate;

      if (
        !productInCartByWarehouse.pickUp &&
        !productInCart.supplierProductDetail.shippingIncluded
      ) {
        shippingCost += getShippingCost(productInCart);
        if (
          productInCart.closestWarehouse?.shippingRate?.flatRate &&
          !flatRateAddedForWarehouse
        ) {
          shippingCost += productInCart.closestWarehouse.shippingRate.flatRate;
          flatRateAddedForWarehouse = true;
        }
      }

      if (productInCart.supplierProductDetail.product.type === "Container")
        deposits +=
          calculateProductPrice(productCost, exchangeRate) *
          productInCart.quantity;

      deposits +=
        calculateProductPrice(
          productInCart?.supplierProductDetail?.containerDeposit,
          exchangeRate
        ) * productInCart.quantity;

      if (productInCart.supplierProductDetail.product.type !== "Container")
        productTotal +=
          calculateProductPrice(
            productCost,
            exchangeRate,
            productInCart.supplierProductDetail.supplier._id
          ) * productInCart.quantity;

      supplierSubtotal += calculateTotalProductAmount(
        productInCart?.supplierProductDetail?.containerDeposit,
        productCost,
        productInCart.quantity
      );

      if (productInCart.supplierProductDetail.product.hasPST) {
        if (facilityHasPST) {
          PSTAmount += customDollarRound(
            calculateTotalProductAmountWithExchange(
              productCost,
              productInCart,
              exchangeRate
            ) * facilityPST
          );
        }
        if (
          !supplierPSTExempt &&
          productInCart.supplierProductDetail.supplier.currency === "CAD"
        ) {
          supplierPSTAmount += customDollarRound(
            calculateTotalProductAmount(
              productInCart?.supplierProductDetail?.containerDeposit,
              productCost,
              productInCart.quantity
            ) * supplierPST
          );
        }
      }

      if (facilityHasGST) {
        GSTAmount += customDollarRound(
          calculateTotalProductAmountWithExchange(
            productCost,
            productInCart,
            exchangeRate,
            shippingCost
          ) * GST
        );
      }
      if (productInCart.supplierProductDetail.supplier.currency === "CAD") {
        supplierGSTAmount += customDollarRound(
          calculateTotalProductAmount(
            productInCart?.supplierProductDetail?.containerDeposit,
            productCost,
            productInCart.quantity
          ) * GST
        );
      }
    });
  });

  shippingCost = customDollarRound(shippingCost);
  if (facilityHasGST) GSTAmount += customDollarRound(shippingCost * GST);

  if (
    productTotal + deposits + shippingCost + GSTAmount + PSTAmount <
    facilityCredit
  ) {
    credit = customDollarRound(
      productTotal + deposits + shippingCost + GSTAmount + PSTAmount
    );
  } else if (facilityCredit > 0) {
    credit = facilityCredit;
  }

  const subtotal = customDollarRound(productTotal + deposits + shippingCost);
  const orderTotal = customDollarRound(
    subtotal + GSTAmount + PSTAmount - credit
  );
  const supplierTotal = customDollarRound(
    supplierSubtotal + supplierGSTAmount + supplierPSTAmount
  );
  return {
    deposits,
    credit,
    GSTAmount,
    PSTAmount,
    productTotal,
    orderTotal,
    supplierSubtotal,
    supplierGSTAmount,
    supplierPSTAmount,
    supplierTotal,
    shippingCost,
    subtotal,
  };
};

export default calculateOrderTotals;
