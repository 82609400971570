import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Fab,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import AddressFields from "../../sharedComponents/TextFields/AddressFields";
import { checkFacilityUserLevelLogger } from "../../../sharedFunctions/userLevels";
import { checkIfValidProductDetails } from "../../../sharedFunctions/checkIfValidProductDetails";
import { checkIfValidAddress } from "../../../sharedFunctions/checkIfValidAddress";
import { CustomModal } from "../../sharedComponents";
import FacilityEmailTextField from "../../sharedComponents/TextFields/FacilityEmailTextField";
import { getProductOptionLabel } from "../../../sharedFunctions/labels";
import ProductCard from "./ProductCard";
//import useUpdateFacilitySetSuggestNewSuppliers "../../../customHooks/facility/useUpdateFacilitySetSuggestNewSuppliers"
import useFacility from "../../../customHooks/facility/useFacility";
import useUpdateFacilityAddress from "../../../customHooks/facility/useUpdateFacilityAddress";
import useUpdateFacilityEmail from "../../../customHooks/facility/useUpdateFacilityEmail";
import useEmailSendProductSupplierRequest from "../../../customHooks/email/useEmailSendProductSupplierRequest";
import useUpdateProductAddRequestingFacility from "../../../customHooks/products/useUpdateProductAddRequestingFacility";
import useExchangeRates from "../../../customHooks/exchangeRates/useExchangeRates";
import calculateProductPrice from "../../../sharedFunctions/purchasingFunctions/calculateProductPrice";
import useSupplierProductDetailsByProduct from "../../../customHooks/supplierProductDetails/useSupplierProductDetailsByProduct";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/reducers/userSlice";
import useEquivalentSupplierProductDetailsByProduct from "../../../customHooks/supplierProductDetails/useEquivalentSupplierProductDetailsByProduct";
import useSupplierPallets from "../../../customHooks/supplierProductDetails/useSupplierPallets";
import useFacilityRestrictedShippers from "../../../customHooks/facility/useFacilityRestrictedShippers";

const ProductView = ({ close, defaultAmount, product }) => {
  let [searchParams] = useSearchParams();

  const facilityID = searchParams.get("id");
  const user = useSelector(selectUser);
  const [defaultAmountToUse, setDefaultAmountToUse] = useState(defaultAmount);
  useFacilityRestrictedShippers(facilityID);

  useSupplierPallets({
    enabled: true,
  });
  const {
    data: supplierProductDetails,
    isLoading: isLoadingSupplierProductDetails,
  } = useSupplierProductDetailsByProduct(product._id);
  const {
    facility,
    facilityUserLevel,
    isLoading: isFacilityLoading,
  } = useFacility(facilityID, user._id);
  const { exchangeRates, isLoading } = useExchangeRates();
  const { data: productEquivalents, isLoading: isLoadingProductEquivalents } =
    useEquivalentSupplierProductDetailsByProduct(product?._id);

  const [address, setAddress] = useState({});
  const [addressOpen, setAddressOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailOpen, setEmailOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showEquivalentProducts, setShowEquivalentProducts] = useState(false);
  const [supplierRequested, setSupplierRequested] = useState(false);

  const emailSendProductSupplierRequest = useEmailSendProductSupplierRequest();
  const updateProductAddRequestingFacility =
    useUpdateProductAddRequestingFacility();
  const { mutateAsync: updateFacilityAddress, isLoading: isLoadingAddress } =
    useUpdateFacilityAddress();
  const { mutateAsync: updateFacilityEmail, isLoading: isLoadingEmails } =
    useUpdateFacilityEmail();
  //const { mutateAsync: updateFacilitySetSuggestNewSuppliers, isLoading: isLoadingSetSuggestNewSuppliers } = useUpdateFacilitySetSuggestNewSuppliers();

  useEffect(() => {
    if (!facility) return;
    if (facility.addressObject) setAddress(facility.addressObject);
    if (
      checkFacilityUserLevelLogger(facilityUserLevel) &&
      !checkIfValidAddress(facility.addressObject)
    )
      setAddressOpen(true);
    else setAddressOpen(false);
    if (
      checkFacilityUserLevelLogger(facilityUserLevel) &&
      !facility.emails?.length
    )
      setEmailOpen(true);
    else setEmailOpen(false);
  }, [facility, facilityUserLevel]);

  const closeAddress = async (event, reason, address) => {
    if (reason === "backdropClick") return;
    if (!address) {
      setAddressOpen(false);
      close();
    }

    if (address) {
      await updateFacilityAddress({ facility: { _id: facilityID, address } });
    }

    setAddressOpen(false);
  };

  const closeEmail = async (event, reason) => {
    if (reason === "backdropClick") return;
    if (!emails.length) {
      setEmailOpen(false);
      close();
    }
    if (emails.length) {
      await updateFacilityEmail({ facility: { _id: facilityID, emails } });
    }
    setEmailOpen(false);
  };

  // const submitFacilitySuggestNewSuppliers = async () => {
  //   await updateFacilitySetSuggestNewSuppliers({ facilityID: facility._id });
  // };

  const submitRequestSupplier = async () => {
    setSaving(true);

    await updateProductAddRequestingFacility.mutateAsync({
      facilityID: facilityID,
      productID: product._id,
    });

    await emailSendProductSupplierRequest.mutateAsync({
      userEmail: user?.email,
      facilityName: facility.name,
      productName: getProductOptionLabel(product),
    });

    setSupplierRequested(true);
    return close();
  };

  const memoizedUpdatedSupplierProductDetails = useMemo(() => {
    return (
      supplierProductDetails
        ?.filter(
          (supplierProductDetail) =>
            checkIfValidProductDetails(supplierProductDetail) &&
            (process.env.NODE_ENV !== "production" ||
              (process.env.NODE_ENV === "production" && facility?.isDemoFacility
                ? supplierProductDetail?.supplier?.isDemoSupplier
                : !supplierProductDetail?.supplier?.isDemoSupplier))
        )
        .map((supplierProductDetail) => {
          const exchangeRate = exchangeRates.find(
            (exchangeRate) =>
              exchangeRate.currency === supplierProductDetail.supplier.currency
          );
          return {
            ...supplierProductDetail,
            price: calculateProductPrice(
              supplierProductDetail.cost,
              exchangeRate?.rate,
              supplierProductDetail.supplier?._id
            ),
          };
        }) || []
    );
  }, [supplierProductDetails, exchangeRates, facility?.isDemoFacility]);

  const memoizedThreeLowestSupplierProductDetails = useMemo(() => {
    return memoizedUpdatedSupplierProductDetails
      .filter((supplierProductDetail) =>
        facility?.approvedSuppliers?.some(
          (approvedSupplier) =>
            approvedSupplier?._id === supplierProductDetail?.supplier._id
        )
      )
      .sort((a, b) => a?.price - b?.price)
      .slice(0, 3);
  }, [memoizedUpdatedSupplierProductDetails, facility?.approvedSuppliers]);

  const highestPrice =
    memoizedThreeLowestSupplierProductDetails.length > 0
      ? memoizedThreeLowestSupplierProductDetails.reduce((a, b) => {
          const first = a.price || a;
          const second = b.price || b;
          return Math.max(first, second);
        })
      : 0;

  const renderRequestProduct = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ display: "flex" }}>
            Suppliers Coming Soon!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ display: "flex" }}>
            Do you require this product now?
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {supplierRequested ? (
            <Typography variant="h6">
              Thank you, your request has been submitted!
            </Typography>
          ) : (
            <Button
              variant="contained"
              fullWidth
              onClick={submitRequestSupplier}
              disabled={saving}
            >
              {saving ? "Requesting Supplier" : "Request Supplier"}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderChooseEquivalentProduct = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Typography sx={{ display: "flex" }}>
            This product is obsolete. Please choose an equivalent product below.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  // const renderSuggestToToggleNewSuppliers = () => {
  //   return (
  //     <Grid container spacing={2} item xs={12}>
  //       <Grid item xs={12}>
  //         <Typography sx={{ display: "flex" }}>
  //           We have New Suppliers for this product would you like to see
  //           pricing?
  //         </Typography>
  //       </Grid>

  //       <Grid item xs={12}>
  //         <Button
  //           disabled={saving}
  //           variant="contained"
  //           fullWidth
  //           onClick={submitFacilitySuggestNewSuppliers}
  //         >
  //           {isLoadingSetSuggestNewSuppliers ? "Loading" : "Yes"}
  //         </Button>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const renderApprovedSuppliers = () => {
    if (
      !memoizedThreeLowestSupplierProductDetails?.length ||
      (!!product?.obsoleteDate && new Date(product?.obsoleteDate) < new Date())
    )
      return;

    let approvedSuppliersToSort = memoizedThreeLowestSupplierProductDetails;
    //if they are a supplier filter out any other suppliers
    if (!checkFacilityUserLevelLogger(facilityUserLevel))
      approvedSuppliersToSort = approvedSuppliersToSort.filter(
        (approvedSupplier) => {
          return approvedSupplier.supplier.email === user.email;
        }
      );

    return approvedSuppliersToSort.slice(0, 3).map((supplierProductDetail) => {
      if (!supplierProductDetail?.supplier) return null;
      return (
        <ProductCard
          key={supplierProductDetail._id}
          defaultAmount={defaultAmountToUse}
          facility={facility}
          facilityUserLevel={facilityUserLevel}
          supplierProductDetail={supplierProductDetail}
          color={"primary"}
          size={4}
          close={close}
          setShowEquivalentProducts={setShowEquivalentProducts}
        />
      );
    });
  };

  const renderSuggestedSuppliers = () => {
    if (product?.obsoleteDate && new Date(product?.obsoleteDate) < new Date())
      return renderChooseEquivalentProduct();
    if (!memoizedUpdatedSupplierProductDetails?.length)
      return renderRequestProduct();

    let suggestedSupplierDetails = memoizedUpdatedSupplierProductDetails
      .filter(
        (supplierProductDetail) =>
          supplierProductDetail.supplier._id !==
          facility?.approvedSuppliers?.find(
            (approvedSupplier) =>
              approvedSupplier?._id === supplierProductDetail?.supplier._id
          )?._id
      )
      .sort((a, b) => {
        const productAContainerSize = a.containerSize;
        const productBContainerSize = b.containerSize;

        return (
          a?.price / productAContainerSize - b?.price / productBContainerSize
        );
      });

    if (
      !memoizedThreeLowestSupplierProductDetails?.length &&
      !suggestedSupplierDetails?.length
    )
      return renderRequestProduct();

    if (!facility?.suggestNonApprovedSuppliers) {
      return (
        !memoizedThreeLowestSupplierProductDetails?.length &&
        renderRequestProduct()
        //renderSuggestToToggleNewSuppliers()
      );
    }

    //if there are less than 3 show a total of 3
    if (memoizedThreeLowestSupplierProductDetails.length < 3)
      return suggestedSupplierDetails
        .map((supplierProductDetail) => {
          return (
            <ProductCard
              key={supplierProductDetail._id}
              defaultAmount={defaultAmountToUse}
              facility={facility}
              facilityUserLevel={facilityUserLevel}
              supplierProductDetail={supplierProductDetail}
              color={"success"}
              size={4}
              close={close}
              setShowEquivalentProducts={setShowEquivalentProducts}
            />
          );
        })
        .slice(0, 3 - memoizedThreeLowestSupplierProductDetails.length);

    if (highestPrice === 0) return;
    //if there are 3 show 1 more
    if (
      memoizedThreeLowestSupplierProductDetails.length >= 3 &&
      suggestedSupplierDetails[0]?.price < highestPrice
    )
      return (
        <ProductCard
          defaultAmount={defaultAmountToUse}
          facility={facility}
          facilityUserLevel={facilityUserLevel}
          supplierProductDetail={suggestedSupplierDetails[0]}
          color={"success"}
          size={12}
          close={close}
          setShowEquivalentProducts={setShowEquivalentProducts}
        />
      );
  };

  const renderEquivalentProducts = () => {
    if (!productEquivalents?.length) return null;

    const sortedSupplierProductDetails = productEquivalents.filter(
      (productDetail) =>
        productDetail?.price !== undefined && productDetail?.price !== null
    );

    if (!sortedSupplierProductDetails.length) return null;

    productEquivalents.sort((a, b) => {
      return a?.price - b?.price;
    });

    //we need to group the equivalent products by product
    const productEquivalentsGrouped = productEquivalents.reduce(
      (acc, productDetail) => {
        const product = productDetail.product._id;
        if (!acc[product]) acc[product] = [];
        acc[product].push(productDetail);
        return acc;
      },
      {}
    );

    return Object.values(productEquivalentsGrouped).map(
      (equivalentProduct, i) => {
        return (
          <Grid
            container
            spacing={2}
            item
            key={"equivalentProduct" + i}
            className="centered-container"
          >
            <Grid item xs={12}>
              <Typography>
                {getProductOptionLabel(equivalentProduct[0].product)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={2}
              className="centered-container"
            >
              {equivalentProduct
                .map((supplierProductDetail) => {
                  const exchangeRate = exchangeRates.find(
                    (exchangeRate) =>
                      exchangeRate.currency ===
                      supplierProductDetail.supplier.currency
                  );

                  const tempSupplierProductDetail = {
                    ...supplierProductDetail,
                  };

                  tempSupplierProductDetail.price = calculateProductPrice(
                    supplierProductDetail.cost,
                    exchangeRate?.rate,
                    supplierProductDetail.supplier?._id
                  );

                  return (
                    <ProductCard
                      key={supplierProductDetail._id}
                      defaultAmount={defaultAmountToUse}
                      facility={facility}
                      facilityUserLevel={facilityUserLevel}
                      originalProductID={product._id}
                      supplierProductDetail={tempSupplierProductDetail}
                      color={"info"}
                      size={4}
                      close={close}
                      setShowEquivalentProducts={setShowEquivalentProducts}
                    />
                  );
                })
                .slice(0, 3)}
            </Grid>
          </Grid>
        );
      }
    );
  };

  if (emailOpen)
    return (
      <CustomModal
        open={emailOpen}
        close={closeEmail}
        title="Please enter a valid facility email to add products to shopping cart."
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FacilityEmailTextField
              emails={emails}
              facilityID={facilityID}
              setEmails={setEmails}
              multiple={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={closeEmail}
              disabled={!emails.length || isLoadingEmails}
            >
              {isLoadingEmails ? "Updating Email" : "Update Email"}
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    );

  if (addressOpen)
    return (
      <CustomModal
        open={addressOpen}
        close={closeAddress}
        title="Please enter a valid address to add products to shopping cart."
      >
        <Grid container spacing={2}>
          <AddressFields
            address={address}
            editMode={true}
            isFacility={true}
            setAddress={setAddress}
          />
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => closeAddress(null, null, address)}
              disabled={!checkIfValidAddress(address) || isLoadingAddress}
            >
              {isLoadingAddress ? "Updating Address" : "Update Address"}
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    );

  if (isLoading || isLoadingSupplierProductDetails || isFacilityLoading)
    return (
      <Grid item xs={12} className="centered-container">
        <CircularProgress />
      </Grid>
    );

  if (!checkFacilityUserLevelLogger(facilityUserLevel)) return <></>;

  return (
    <Grid container item spacing={2} className="centered-container">
      {renderApprovedSuppliers()}
      {renderSuggestedSuppliers()}
      <Grid item xs={12} className="center-both-container">
        <Typography variant="h6" sx={{ mr: 1, ml: -4 }}>
          Qty:{" "}
        </Typography>

        <Fab
          aria-label="Remove"
          size="small"
          onClick={() => {
            if (defaultAmountToUse > 0) {
              setDefaultAmountToUse(defaultAmountToUse - 1);
            }
          }}
        >
          <RemoveIcon />
        </Fab>

        <TextField
          value={defaultAmountToUse}
          onChange={(e) => {
            if (e.target.value >= 0) {
              setDefaultAmountToUse(parseInt(e.target.value));
            }
          }}
          sx={{ width: "75px", mx: 1 }}
          type="number"
          inputProps={{ min: 0, style: { textAlign: "center" } }}
        />
        <Fab
          aria-label="Add"
          size="small"
          onClick={() => {
            setDefaultAmountToUse(defaultAmountToUse + 1);
          }}
        >
          <AddIcon />
        </Fab>
      </Grid>

      {product?.equivalents?.length > 0 && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setShowEquivalentProducts(!showEquivalentProducts)}
          >
            Show Equivalent Products
          </Button>
        </Grid>
      )}
      {showEquivalentProducts &&
        (isLoadingProductEquivalents ? (
          <Grid item xs={12} className="centered-container">
            <CircularProgress />
          </Grid>
        ) : (
          renderEquivalentProducts()
        ))}
    </Grid>
  );
};

export default ProductView;
