import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import AddressFields from "../../sharedComponents/TextFields/AddressFields";
import AppError from "../../ErrorBoundaries/AppError";
import ContactFacilities from "../ContactFacilities";
import ContactFields from "./ContactFields";
import ContactProducts from "../ContactProducts";
import getUserRole from "../../../sharedFunctions/getUserRole";
import {
  selectAppUserLevel,
  selectFacilityUserLevel,
  selectShipperUserLevel,
  selectSupplierUserLevel,
} from "../../../redux/reducers/userLevelsSlice";
import ToggleFields from "./ToggleFields";
import useAddSupplierCompany from "../../../customHooks/supplierCompany/useAddSupplierCompany";
import useQuickBooksUpdateVendor from "../../../customHooks/quickBooks/useQuickBooksUpdateVendor";
import useQuickBooksCreateVendor from "../../../customHooks/quickBooks/useQuickBooksCreateVendor";
import useCheckIfEmailExistsCallback from "../../../customHooks/contacts/useCheckIfEmailExistsCallback";
import useAddContact from "../../../customHooks/contacts/useAddContact";
import useUpdateContact from "../../../customHooks/contacts/useUpdateContact";
import useAddShipperCompany from "../../../customHooks/shipperCompany/useAddShipperCompany";
import useUpdateFacilityUpdateUserRole from "../../../customHooks/facility/useUpdateFacilityUpdateUserRole";
import useUpdateSupplierCompanyUserRole from "../../../customHooks/supplierCompany/useUpdateSupplierCompanyUserRole";
import useUpdateShipperCompanyUserRole from "../../../customHooks/shipperCompany/useUpdateShipperCompanyUserRole";
import useAddDefaultSupplierServiceDetails from "../../../customHooks/service/useAddDefaultSupplierServiceDetails";
import RemoveContactFromCompany from "../../employees/RemoveContactFromCompany";
import { useAuth0 } from "@auth0/auth0-react";
import ProfilePicture from "./ProfilePicture";

const ContactForm = ({
  close,
  companyRoles,
  contact,
  editMode,
  facilityID,
  supplierCompanyID,
  shipperCompanyID,
  setEditMode,
  isProfile,
  isABusiness,
  isAWarehouse,
  isASupplier,
  isAShipper,
  isASupplierCompany,
  isFacilityOwner,
  parentCompanyOptions,
  onSave,
}) => {
  const { user } = useAuth0();
  const appUserLevel = useSelector(selectAppUserLevel);
  const facilityUserLevel = useSelector(selectFacilityUserLevel);
  const shipperCompanyUserLevel = useSelector(selectShipperUserLevel);
  const supplierUserLevel = useSelector(selectSupplierUserLevel);
  const addSupplierCompanyMutation = useAddSupplierCompany();
  const addDefaultSupplierServiceDetails =
    useAddDefaultSupplierServiceDetails();
  const checkIfEmailExistsCallback = useCheckIfEmailExistsCallback();
  const addContact = useAddContact();
  const addShipperCompany = useAddShipperCompany();
  const updateContact = useUpdateContact();
  const updateFacilityUpdateUserRole = useUpdateFacilityUpdateUserRole();
  const updateSupplierCompanyUserRole = useUpdateSupplierCompanyUserRole();
  const updateShipperCompanyUserRole = useUpdateShipperCompanyUserRole();
  const quickBooksUpdateVendor = useQuickBooksUpdateVendor();
  const quickBooksCreateVendor = useQuickBooksCreateVendor();

  const [altEmail, setAltEmail] = useState(
    contact?.altEmails?.length ? contact?.altEmails[0] : ""
  );
  const [address, setAddress] = useState(
    contact?.address ?? {
      city: "",
      country: "Canada",
      coordinates: "",
      hasCoordinates: false,
      province: "",
      postalCode: "",
      secondaryAddress: "",
      streetAddress: "",
    }
  );
  const [billingEmail, setBillingEmail] = useState(contact?.billingEmail ?? "");
  const [currency, setCurrency] = useState(contact?.currency ?? "CAD");
  const [companyName, setCompanyName] = useState(contact?.companyName ?? "");
  const [dispatchEmail, setDispatchEmail] = useState(
    contact?.dispatchEmail ?? ""
  );
  const [email, setEmail] = useState(contact?.email ?? "");
  const [emailError, setEmailError] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [expectedResponseTime, setExpectedResponseTime] = useState(
    contact?.expectedResponseTime ?? ""
  );
  const [altEmailError, setAltEmailError] = useState(false);
  const [firstName, setFirstName] = useState(contact?.firstName ?? "");
  const [GSTAccountNumber, setGSTAccountNumber] = useState(
    contact?.GSTAccountNumber ?? ""
  );
  const [isBusiness, setIsBusiness] = useState(
    contact?.isBusiness ?? isABusiness ?? isFacilityOwner ?? false
  );
  const [isContractOperator, setIsContractOperator] = useState(
    contact?.isContractOperator ?? false
  );
  const [isContractingShipper, setIsContractingShipper] = useState(
    contact?.isContractingShipper ?? false
  );
  const [isDemoSupplier, setIsDemoSupplier] = useState(
    contact?.isDemoSupplier ?? false
  );
  const [isFacilityManager, setIsFacilityManager] = useState(
    contact?.isFacilityManager ?? false
  );
  const [isManufacturer, setIsManufacturer] = useState(
    contact?.isManufacturer ?? false
  );
  const [isRegulator, setIsRegulator] = useState(contact?.isRegulator ?? false);
  const [isServiceProvider, setIsServiceProvider] = useState(
    contact?.isServiceProvider ?? false
  );
  const [isShipper, setIsShipper] = useState(
    contact?.isShipper ?? isAShipper ?? false
  );
  const [isSupplier, setIsSupplier] = useState(
    contact?.isSupplier ?? isASupplier ? true : false
  );
  const [isWarehouse, setIsWarehouse] = useState(
    contact?.isWarehouse ?? isAWarehouse ? true : false
  );
  const [lastName, setLastName] = useState(contact?.lastName ?? "");
  const [mobile, setMobile] = useState(contact?.mobile ?? "");
  const [name, setName] = useState(contact?.name ?? "");
  const [orderEmails, setOrderEmails] = useState(contact?.orderEmails ?? []);
  const [paymentTerms, setPaymentTerms] = useState(contact?.paymentTerms ?? "");
  const [parentCompanies, setParentCompanies] = useState(
    contact?.parentCompanies ?? parentCompanyOptions ?? []
  );

  const [phoneExtension, setPhoneExtension] = useState(
    contact?.phoneExtension ?? ""
  );
  const [phone, setPhone] = useState(contact?.phone ?? "");
  const [pickUpHours, setPickUpHours] = useState(contact?.pickUpHours ?? "");
  const [PSTAccountNumber, setPSTAccountNumber] = useState(
    contact?.PSTAccountNumber ?? ""
  );
  const [PSTExempt, setPSTExempt] = useState(contact?.PSTExempt ?? false);
  const [ratesEmail, setRatesEmail] = useState(contact?.ratesEmail ?? "");
  const [saving, setSaving] = useState(false);
  const [serviceBookingPhone, setServiceBookingPhone] = useState(
    contact?.serviceBookingPhone ?? ""
  );
  const [shippingEmails, setShippingEmails] = useState(
    contact?.shippingEmails ?? []
  );
  const [shippingDimensionUnit, setShippingDimensionUnit] = useState(
    contact?.shippingDimensionUnit
  );
  const [shippingWeightUnit, setShippingWeightUnit] = useState(
    contact?.shippingWeightUnit
  );
  const [hasShippingPST, setHasShippingPST] = useState(
    contact?.hasShippingPST ?? false
  );
  const [submitError, setSubmitError] = useState(false);
  const [useAppCredits, setUseAppCredits] = useState(
    contact?.useAppCredits ?? false
  );
  const [useDrOPsShipping, setUseDrOPsShipping] = useState(
    contact?.useDrOPsShipping ?? false
  );
  const [useSocialLogInProfilePicture, setUseSocialLogInProfilePicture] =
    useState(!!contact?.useSocialLogInProfilePicture);

  const [website, setWebsite] = useState(contact?.website ?? "");
  const [userRole, setUserRole] = useState(
    getUserRole(contact?._id, companyRoles) ?? ""
  );
  const initialUserRole = getUserRole(contact?._id, companyRoles) ?? "";

  const onSubmit = async () => {
    try {
      setSaving(true);
      if (email && email !== contact?.email) {
        const result = await checkIfEmailExistsCallback({
          email,
          contactID: contact?._id,
        });
        if (result) {
          setEmailError(true);
          setSaving(false);
          return;
        }
      }

      if (altEmail && altEmail !== contact?.altEmail) {
        const result = await checkIfEmailExistsCallback({
          email: altEmail,
          contactID: contact?._id,
        });
        if (result) {
          setAltEmailError(true);
          setSaving(false);
          return;
        }
      }

      const contactName =
        isBusiness || isWarehouse
          ? name
          : [firstName, lastName].filter(Boolean).join(" ") || "";

      const altEmails = [altEmail.trim()];
      const newUser =
        !isSupplier &&
        !isShipper &&
        !isServiceProvider &&
        !parentCompanies?.length;

      let body = {
        _id: contact?._id,
        altEmails,
        address,
        companyName,
        parentCompanies,
        email: email.trim() || null,
        firstName,
        GSTAccountNumber,
        PSTAccountNumber,
        isBusiness,
        isContractOperator,
        isContractingShipper,
        isFacilityManager,
        isManufacturer,
        isRegulator,
        isServiceProvider,
        isShipper,
        isSupplier,
        isWarehouse,
        lastName,
        mobile,
        phone,
        phoneExtension,
        website,
        name: contactName,
        newUser,
        useSocialLogInProfilePicture,
      };
      if (isBusiness)
        body = {
          ...body,
          paymentTerms,
          FOBLocation: isBusiness && address.city + ", " + address.province,
        };
      if (isBusiness && (isSupplier || isServiceProvider))
        body = {
          ...body,
          useAppCredits,
          isDemoSupplier,
          PSTExempt,
          hasShippingPST,
        };
      if (isBusiness && isSupplier) body = { ...body, useDrOPsShipping };
      if ((isBusiness && isShipper) || isWarehouse)
        body = { ...body, currency };
      if (isBusiness && isShipper)
        body = { ...body, dispatchEmail, ratesEmail };
      if (isWarehouse || (isBusiness && (isSupplier || isServiceProvider)))
        body = {
          ...body,
          billingEmail,
          currency,
          orderEmails,
          shippingEmails,
          pickUpHours,
          shippingDimensionUnit,
          shippingWeightUnit,
        };
      if (isServiceProvider)
        body = { ...body, expectedResponseTime, serviceBookingPhone };

      let contactUpdated;
      if (contact) {
        contactUpdated = await updateContact.mutateAsync(body);
        if (
          ((isBusiness && isShipper) ||
            (isSupplier && isBusiness) ||
            (isServiceProvider && isBusiness)) &&
          ((contactName && contactName !== contact.name) ||
            (companyName && companyName !== contact.companyName) ||
            (billingEmail && billingEmail !== contact.billingEmail))
        ) {
          try {
            quickBooksUpdateVendor.mutateAsync({
              vendor: {
                companyName: companyName,
                vendorID: contact.vendorID,
                name: contactName,
                billingEmail: billingEmail,
                vendorSyncToken: contact.vendorSyncToken,
              },
            });
          } catch (error) {}
        }
      } else {
        contactUpdated = await addContact.mutateAsync(body);
      }

      if (facilityID && userRole !== initialUserRole) {
        updateFacilityUpdateUserRole.mutate({
          contactID: contactUpdated._id,
          facilityID,
          userRole,
        });
      } else if (supplierCompanyID && userRole !== initialUserRole) {
        updateSupplierCompanyUserRole.mutate({
          contactID: contactUpdated._id,
          supplierCompanyID,
          userRole,
        });
      } else if (shipperCompanyID && userRole !== initialUserRole) {
        updateShipperCompanyUserRole.mutate({
          contactID: contactUpdated._id,
          shipperCompanyID,
          userRole,
        });
      }
      //if contact is a supplier company, and it was not before, add it to the supplier company collection
      //if contact is a supplier company that is now a warehouse add itself as warehouse to the supplier company
      if (
        isBusiness &&
        (isSupplier || isServiceProvider) &&
        ((!contact?.isSupplier && !contact?.isServiceProvider) ||
          !contact?.isBusiness ||
          (!contact?.isWarehouse && isWarehouse))
      ) {
        await addSupplierCompanyMutation.mutateAsync({
          contactID: contactUpdated._id,
          isWarehouse,
        });
      }

      if (
        (isSupplier && isBusiness) ||
        (isServiceProvider && isBusiness) ||
        (isBusiness && isShipper)
      ) {
        if (!contactUpdated.vendorID) {
          try {
            quickBooksCreateVendor.mutateAsync({
              billingEmail: contactUpdated.billingEmail,
              companyName: contactUpdated.companyName,
              contactID: contactUpdated._id,
              currency: contactUpdated.currency,
              vendorName: contactUpdated.name,
            });
          } catch (error) {}
        }
      }

      if (!contact && isBusiness && isShipper) {
        addShipperCompany.mutate({ contactID: contactUpdated._id });
      }

      //if a supplier company toggles serviceProvider add default service details
      if (isBusiness && isServiceProvider && !contact?.isServiceProvider) {
        addDefaultSupplierServiceDetails.mutate(contactUpdated._id);
      }

      setEditMode && setEditMode(false);
      setSaving(false);
      if (close) close(null, null, contactUpdated);
      if (onSave) onSave(contactUpdated);
    } catch (error) {
      if (error?.response?.data?.type === "EmailValidationError") {
        setEmailValidationError(true);
      } else {
        setSubmitError(true);
      }
      setSaving(false);

      throw error;
    }
  };

  if (submitError) {
    return (
      <AppError
        message="There was a problem submitting the Contact."
        type="Contact"
      />
    );
  }

  return (
    <Grid container spacing={2}>
      {appUserLevel?.includes("Super User") && (
        <>
          {editMode && contact?.parentCompanies?.length > 0 && (
            <RemoveContactFromCompany
              closeForm={close}
              contact={contact}
              parentCompanies={parentCompanies}
              type={
                contact.isSupplier || contact.isWarehouse
                  ? "supplier"
                  : contact.isShipper
                  ? "shipper"
                  : "facility"
              }
            />
          )}
          <ToggleFields
            editMode={editMode}
            appUserLevel={appUserLevel}
            isBusiness={isBusiness}
            setIsBusiness={setIsBusiness}
            isContractOperator={isContractOperator}
            setIsContractOperator={setIsContractOperator}
            isContractingShipper={isContractingShipper}
            setIsContractingShipper={setIsContractingShipper}
            isFacilityManager={isFacilityManager}
            setIsFacilityManager={setIsFacilityManager}
            isManufacturer={isManufacturer}
            setIsManufacturer={setIsManufacturer}
            isRegulator={isRegulator}
            setIsRegulator={setIsRegulator}
            isServiceProvider={isServiceProvider}
            setIsServiceProvider={setIsServiceProvider}
            isShipper={isShipper}
            setIsShipper={setIsShipper}
            isWarehouse={isWarehouse}
            setIsWarehouse={setIsWarehouse}
            isSupplier={isSupplier}
            setIsSupplier={setIsSupplier}
          />
        </>
      )}
      <ContactFields
        billingEmail={billingEmail}
        setBillingEmail={setBillingEmail}
        currency={currency}
        setCurrency={setCurrency}
        editMode={editMode}
        contact={contact}
        appUserLevel={appUserLevel}
        expectedResponseTime={expectedResponseTime}
        setExpectedResponseTime={setExpectedResponseTime}
        facilityUserLevel={facilityUserLevel}
        shipperCompanyUserLevel={shipperCompanyUserLevel}
        supplierUserLevel={supplierUserLevel}
        companyName={companyName}
        setCompanyName={setCompanyName}
        dispatchEmail={dispatchEmail}
        setDispatchEmail={setDispatchEmail}
        isASupplierCompany={isASupplierCompany}
        isAWarehouse={isAWarehouse}
        isBusiness={isBusiness}
        isDemoSupplier={isDemoSupplier}
        setIsDemoSupplier={setIsDemoSupplier}
        isServiceProvider={isServiceProvider}
        isSupplier={isSupplier}
        isShipper={isShipper}
        isWarehouse={isWarehouse}
        setEmailError={setEmailError}
        setEmailValidationError={setEmailValidationError}
        setAltEmailError={setAltEmailError}
        name={name}
        setName={setName}
        orderEmails={orderEmails}
        setOrderEmails={setOrderEmails}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        altEmail={altEmail}
        setAltEmail={setAltEmail}
        paymentTerms={paymentTerms}
        setPaymentTerms={setPaymentTerms}
        parentCompanies={parentCompanies}
        setParentCompanies={setParentCompanies}
        parentCompanyOptions={parentCompanyOptions}
        phone={phone}
        setPhone={setPhone}
        phoneExtension={phoneExtension}
        setPhoneExtension={setPhoneExtension}
        pickUpHours={pickUpHours}
        setPickUpHours={setPickUpHours}
        PSTExempt={PSTExempt}
        setPSTExempt={setPSTExempt}
        hasShippingPST={hasShippingPST}
        setHasShippingPST={setHasShippingPST}
        shippingDimensionUnit={shippingDimensionUnit}
        setShippingDimensionUnit={setShippingDimensionUnit}
        shippingWeightUnit={shippingWeightUnit}
        setShippingWeightUnit={setShippingWeightUnit}
        shippingEmails={shippingEmails}
        setShippingEmails={setShippingEmails}
        ratesEmail={ratesEmail}
        setRatesEmail={setRatesEmail}
        serviceBookingPhone={serviceBookingPhone}
        setServiceBookingPhone={setServiceBookingPhone}
        mobile={mobile}
        setMobile={setMobile}
        website={website}
        setWebsite={setWebsite}
        userRole={userRole}
        setUserRole={setUserRole}
        facilityID={facilityID}
        supplierCompanyID={supplierCompanyID}
        shipperCompanyID={shipperCompanyID}
        useAppCredits={useAppCredits}
        setUseAppCredits={setUseAppCredits}
        useDrOPsShipping={useDrOPsShipping}
        setUseDrOPsShipping={setUseDrOPsShipping}
      />

      {isASupplierCompany && (
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              label="GST Account Number"
              value={GSTAccountNumber}
              onChange={(e) => setGSTAccountNumber(e.target.value)}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              label="PST Account Number"
              value={PSTAccountNumber}
              onChange={(e) => setPSTAccountNumber(e.target.value)}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
      )}

      <AddressFields
        editMode={editMode}
        isBusiness={isBusiness}
        address={address}
        setAddress={setAddress}
      />

      {emailError && (
        <Grid item xs={12}>
          <Typography color={"error"} align={"center"}>
            The email you have chosen is already being used for a different
            profile.
          </Typography>
        </Grid>
      )}
      {emailValidationError && (
        <Grid item xs={12}>
          <Typography color={"error"} align={"center"}>
            The email you have chosen is invalid.
          </Typography>
        </Grid>
      )}
      {altEmailError && (
        <Grid item xs={12}>
          <Typography color={"error"} align={"center"}>
            An alt email you have chosen is already being used for a different
            profile.
          </Typography>
        </Grid>
      )}
      {editMode && (
        <Grid item xs={12}>
          <Button
            disabled={
              saving || emailError || altEmailError || emailValidationError
            }
            onClick={onSubmit}
            variant="contained"
            fullWidth
          >
            {contact
              ? saving
                ? "Updating Contact"
                : "Update Contact"
              : saving
              ? "Adding Contact"
              : "Add Contact"}
          </Button>
        </Grid>
      )}
      {contact &&
        !isWarehouse &&
        !isBusiness &&
        contact.email === user.email && (
          <ProfilePicture
            contactID={contact._id}
            editMode={editMode}
            profilePicture={contact.profilePicture}
            user={user}
            useSocialLogInProfilePicture={useSocialLogInProfilePicture}
            setUseSocialLogInProfilePicture={setUseSocialLogInProfilePicture}
          />
        )}
      {appUserLevel?.includes("Super User") && !isProfile && contact && (
        <ContactFacilities contact={contact} />
      )}
      {contact?.isManufacturer && <ContactProducts contact={contact} />}
    </Grid>
  );
};

export default ContactForm;
