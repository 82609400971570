import React, { useEffect, useState } from "react";
import {
  Grid,
  CardHeader,
  CardContent,
  TextField,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  Button,
  CircularProgress,
  Drawer,
  Tooltip,
  Badge,
  Divider,
  Card,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProductsInCartByWarehouse,
  setProductsInCartByWarehouse,
  selectOrderFacility,
  setOrderFacility,
  setOrderFacilityAddress,
} from "../../redux/reducers/productsSlice";
import { checkFacilityUserLevelOrder } from "../../sharedFunctions/userLevels";
import { CustomModal } from "../sharedComponents";
import { CancelCartSubmission, ConfirmOrder, RequestOrder } from "../orders";
import moment from "moment";
import ReturnableContainers from "./ReturnableContainers";
import ShoppingCartTotal from "./ShoppingCartTotal";
import ShoppingCartProducts from "./ShoppingCartProducts";
import { useSearchParams } from "react-router-dom";
import useContact from "../../customHooks/contacts/useContact";
import useCartSubmissionsByRequestedUser from "../../customHooks/cartSubmissions/useCartSubmissionsByRequestedUser";
import { selectFacilityUserLevel } from "../../redux/reducers/userLevelsSlice";
import useExchangeRates from "../../customHooks/exchangeRates/useExchangeRates";
import { useFacilityNavigateCallback } from "../../customHooks/facility/useFacilityNavigate";
import useFacilityInfo from "../../customHooks/facility/useFacilityInfo";
import useFacilityPreFilterSupplies from "../../customHooks/facility/useFacilityPreFilterSupplies";
import useFacilityContacts from "../../customHooks/facility/useFacilityContacts";
import { getProductsInCartByWarehouse } from "./helperFunctions/getProductsInCartByWarehouse";
import useContainerSupplyByFacility from "../../customHooks/containerSupply/useContainerSupplyByFacility";
import useChemicalSuppliesByFacility from "../../customHooks/chemicalSupply/useChemicalSuppliesByFacility";
import useSupplierPallets from "../../customHooks/supplierProductDetails/useSupplierPallets";
import useFacilityRestrictedShippers from "../../customHooks/facility/useFacilityRestrictedShippers";
import useShipperCompanies from "../../customHooks/shipperCompany/useShipperCompanies";

const ShoppingCartButton = () => {
  const productsInCartByWarehouse = useSelector(
    selectProductsInCartByWarehouse
  );
  const productsInCart = getProductsInCartByWarehouse(
    productsInCartByWarehouse
  );
  const { contact } = useContact();
  const { cartSubmissions } = useCartSubmissionsByRequestedUser(contact?._id);
  const orderFacility = useSelector(selectOrderFacility);

  useFacilityRestrictedShippers(orderFacility);
  useShipperCompanies();

  useSupplierPallets({
    enabled: !!(
      productsInCartByWarehouse?.length &&
      productsInCartByWarehouse.some(
        (productInCartByWarehouse) => productInCartByWarehouse.freightShipment
      )
    ),
  });

  const [shoppingCartOpen, setShoppingCartOpen] = useState(false);

  const handleToggleShoppingCart = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShoppingCartOpen(!shoppingCartOpen);
  };

  return (
    <>
      <Box>
        <Tooltip title="Open Shopping Cart">
          <Badge
            badgeContent={
              productsInCart.filter((product) =>
                product.supplierProductDetail.product.containerType ===
                  "Pallet" && !product.supplierProductDetail.isReturn
                  ? false
                  : true
              ).length
            }
            color="error"
            sx={{ top: 8, right: 5 }}
          >
            <IconButton
              onClick={handleToggleShoppingCart}
              aria-label="delete"
              sx={{ color: "white", top: -8 }}
              size="large"
            >
              <ShoppingCartIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
      {shoppingCartOpen && (
        <ShoppingCart
          cartSubmissions={cartSubmissions}
          contact={contact}
          handleToggleShoppingCart={handleToggleShoppingCart}
          productsInCartByWarehouse={productsInCartByWarehouse}
          shoppingCartOpen={shoppingCartOpen}
        />
      )}
    </>
  );
};

const ShoppingCart = ({
  cartSubmissions,
  contact,
  handleToggleShoppingCart,
  productsInCartByWarehouse,
  shoppingCartOpen,
}) => {
  const [searchParams] = useSearchParams();
  const facilityID = searchParams.get("id");
  const dispatch = useDispatch();
  const navigate = useFacilityNavigateCallback();
  const orderFacility = useSelector(selectOrderFacility);

  const { facility, isLoading: facilityIsLoading } =
    useFacilityInfo(orderFacility);
  const { facilityContacts } = useFacilityContacts(orderFacility);
  const { data: chemicalSupplies } =
    useChemicalSuppliesByFacility(orderFacility);
  const { data: containerSupplies } = useContainerSupplyByFacility(facilityID);
  const { preFilterSupplies } = useFacilityPreFilterSupplies(orderFacility);

  const userLevel = useSelector(selectFacilityUserLevel);
  const [cancelOrderOpen, setCancelOrderOpen] = useState(false);
  const [cartSubmission, setCartSubmission] = useState(null);
  const [cartSubmissionToRemove, setCartSubmissionToRemove] = useState(null);
  const [orderOpen, setConfirmOrderOpen] = useState(false);
  const [requestOrderOpen, setRequestOrderOpen] = useState(false);
  const [customerPONumber, setCustomerPONumber] = useState("");
  const [notes, setNotes] = useState("");

  const { exchangeRates, isLoading } = useExchangeRates();

  useEffect(() => {
    if (!cartSubmissions?.length) return;
    setCartSubmission(cartSubmissions[0]);
  }, [cartSubmissions]);

  const closeShoppingCart = () => {
    setCustomerPONumber("");
    setNotes("");
    handleToggleShoppingCart();
  };

  const closeConfirmOrder = (event, reason) => {
    if (reason === "backdropClick") return;
    setConfirmOrderOpen(false);
  };

  const closeRequestOrder = (event, reason) => {
    if (reason === "backdropClick") return;
    setRequestOrderOpen(false);
  };

  const closeCancelCartSubmission = (event, reason) => {
    if (reason === "backdropClick") return;
    setCancelOrderOpen(false);
    handleToggleShoppingCart();
  };

  const openConfirmModal = () => {
    const body = {
      date: new Date(),
      facility: {
        _id: facility?._id,
        addressObject: facility?.addressObject,
        administrators: facilityContacts?.administrators,
        chemicalSupplies: chemicalSupplies,
        containerSupplies: containerSupplies,
        credit: facility?.credit,
        hasPST: facility?.hasPST,
        hasGST: facility?.hasGST,
        leadOperators: facilityContacts?.leadOperators,
        managers: facilityContacts?.managers,
        name: facility?.name,
        operators: facilityContacts?.operators,
        preFilterSupplies: preFilterSupplies,
        regulators: facilityContacts?.regulators,
        owners: facilityContacts?.owners,
      },
      requestingUser: contact._id,
      requestedUser: contact._id,
      customerPONumber,
      notes,
    };
    setCartSubmissionToRemove(cartSubmission);
    setCartSubmission(body);
    setConfirmOrderOpen(true);
  };

  const openRequestOrder = () => {
    const body = {
      date: new Date(),
      facility: {
        _id: facility?._id,
        addressObject: facility?.addressObject,
        administrators: facilityContacts?.administrators,
        chemicalSupplies: chemicalSupplies,
        containerSupplies: containerSupplies,
        credit: facility?.credit,
        hasPST: facility?.hasPST,
        hasGST: facility?.hasGST,
        leadOperators: facilityContacts?.leadOperators,
        managers: facilityContacts?.managers,
        name: facility?.name,
        operators: facilityContacts?.operators,
        preFilterSupplies: preFilterSupplies,
        regulators: facilityContacts?.regulators,
        owners: facilityContacts?.owners,
      },
      requestingUser: contact._id,
      requestedUser: contact._id,
      customerPONumber,
      notes,
    };
    setCartSubmissionToRemove(cartSubmission);
    setCartSubmission(body);
    setRequestOrderOpen(true);
  };

  const renderShoppingCart = () => {
    return (
      <Box
        sx={{
          width: "450px",
          "@media (max-width: 450px)": {
            width: "100vw", // Ensure the box takes up the full viewport width when the screen is smaller than 450px
          },
          height: "100%",
        }}
        role="presentation"
      >
        <Card sx={{ height: "100%", overflowY: "scroll" }}>
          <CardHeader
            title="Checkout"
            action={
              <IconButton aria-label="close" onClick={handleToggleShoppingCart}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent sx={{ height: "100%" }}>
            {orderFacility && (isLoading || facilityIsLoading) ? (
              <Grid
                item
                xs={12}
                className="center-both-container"
                sx={{ height: "100vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {orderFacility && (
                  <Grid item xs={12}>
                    <Typography>Facility: {facility?.name}</Typography>
                    {facility?.credit > 0 && (
                      <Typography color={"limegreen"}>
                        Available Credit: ${facility?.credit?.toFixed(2)}
                      </Typography>
                    )}
                  </Grid>
                )}

                {cartSubmissions?.length > 1 && (
                  <Grid item xs={12}>
                    <Autocomplete
                      disableClearable
                      options={cartSubmissions}
                      getOptionLabel={(option) => {
                        const index = cartSubmissions.findIndex(
                          (cartSubmission) =>
                            cartSubmission.date === option.date
                        );
                        return (
                          `Order ${index + 1} ${moment(option.date).format(
                            "MM/DD/YYYY"
                          )}` ?? ""
                        );
                      }}
                      value={cartSubmission}
                      isOptionEqualToValue={(option, value) => {
                        if (!value.length) return [];
                        return option.date === value.date;
                      }}
                      onChange={(e, value) => {
                        setCartSubmission({ ...value });
                        dispatch(setOrderFacility(value.facility._id));
                        dispatch(
                          setOrderFacilityAddress(value.facility.addressObject)
                        );
                        dispatch(
                          setProductsInCartByWarehouse(
                            value.productsInCartByWarehouse
                          )
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          {...params}
                          label="Order"
                        />
                      )}
                    />
                  </Grid>
                )}
                {productsInCartByWarehouse.length > 0 ? (
                  <>
                    <ShoppingCartProducts
                      exchangeRates={exchangeRates}
                      productsInCartByWarehouse={productsInCartByWarehouse}
                      setProductsInCartByWarehouse={
                        setProductsInCartByWarehouse
                      }
                      facility={facility}
                    />
                    <ShoppingCartTotal
                      exchangeRates={exchangeRates}
                      productsInCartByWarehouse={productsInCartByWarehouse}
                      facilityHasGST={facility?.hasGST}
                      facilityHasPST={facility?.hasPST}
                      facilityCredit={facility?.credit}
                      facilityProvince={facility?.addressObject?.province}
                      hasOnlyShippingIncluded={productsInCartByWarehouse.every(
                        (productInCart) =>
                          productInCart.shippingIncluded || productInCart.pickUp
                      )}
                    />
                    <ReturnableContainers
                      exchangeRates={exchangeRates}
                      facility={facility}
                      containerSupplies={containerSupplies}
                    />
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="off"
                        label="PO Number (optional)"
                        value={customerPONumber}
                        variant="outlined"
                        onChange={(e) => setCustomerPONumber(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="off"
                        label="Notes"
                        value={notes}
                        variant="outlined"
                        onChange={(e) => setNotes(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    {productsInCartByWarehouse.some(
                      (p) =>
                        p.closestWarehouse?.contact?.address?.country !==
                        "Canada"
                    ) && (
                      <Grid item xs={12}>
                        <Typography>
                          * Items are expected to have a longer delivery time
                        </Typography>
                      </Grid>
                    )}
                    {(!facility?.isDemoFacility ||
                      process.env.NODE_ENV === "development") && (
                      <>
                        {facilityID === orderFacility ? (
                          <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>

                            {userLevel.includes("Super User") ? (
                              <>
                                <Grid item xs={6} marginBottom={1}>
                                  <Button
                                    onClick={openConfirmModal}
                                    variant="contained"
                                    fullWidth
                                    disabled={
                                      productsInCartByWarehouse.length === 0
                                    }
                                  >
                                    {"Confirm Order"}
                                  </Button>
                                </Grid>
                                <Grid item xs={6} marginBottom={1}>
                                  <Button
                                    onClick={openRequestOrder}
                                    variant="contained"
                                    fullWidth
                                    disabled={
                                      productsInCartByWarehouse.length === 0
                                    }
                                  >
                                    {"Request Order"}
                                  </Button>
                                </Grid>
                              </>
                            ) : (
                              <Grid
                                item
                                xs={cartSubmission ? 6 : 12}
                                marginBottom={1}
                              >
                                <Button
                                  onClick={
                                    checkFacilityUserLevelOrder(userLevel)
                                      ? openConfirmModal
                                      : openRequestOrder
                                  }
                                  variant="contained"
                                  fullWidth
                                  disabled={
                                    productsInCartByWarehouse.length === 0
                                  }
                                >
                                  {checkFacilityUserLevelOrder(userLevel)
                                    ? "Confirm Order"
                                    : "Request Order"}
                                </Button>
                              </Grid>
                            )}

                            {cartSubmissions?.length > 0 && (
                              <Grid item xs={6}>
                                <Button
                                  onClick={() => setCancelOrderOpen(true)}
                                  variant="contained"
                                  fullWidth
                                  disabled={
                                    productsInCartByWarehouse.length === 0
                                  }
                                  color="error"
                                >
                                  Cancel Order
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() =>
                                navigate({
                                  _id: orderFacility,
                                  chemicalSupplies: chemicalSupplies,
                                })
                              }
                            >
                              Navigate To Facility
                            </Button>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "100%" }}
                    className="center-both-container"
                  >
                    <Typography sx={{ height: "calc(100vh - 105px)" }}>
                      No Products in cart
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  return (
    <>
      <CustomModal
        open={orderOpen}
        close={closeConfirmOrder}
        title={"Please confirm your order"}
      >
        <ConfirmOrder
          cartSubmission={cartSubmission}
          cartSubmissions={cartSubmissions}
          cartSubmissionToRemove={cartSubmissionToRemove}
          contactID={contact._id}
          facilityContacts={facilityContacts}
          productsInCartByWarehouse={productsInCartByWarehouse}
          setCartSubmission={setCartSubmission}
          close={closeConfirmOrder}
          closeShoppingCart={closeShoppingCart}
        />
      </CustomModal>

      <CustomModal
        open={requestOrderOpen}
        close={closeRequestOrder}
        title={"You do not have the correct permission to submit an order"}
      >
        <RequestOrder
          cartSubmission={cartSubmission}
          customerPONumber={customerPONumber}
          close={closeRequestOrder}
          closeShoppingCart={closeShoppingCart}
          notes={notes}
        />
      </CustomModal>

      <CustomModal
        open={cancelOrderOpen}
        close={closeCancelCartSubmission}
        title={"Are you sure you want to cancel this order?"}
      >
        <CancelCartSubmission
          cartSubmission={cartSubmission}
          cartSubmissions={cartSubmissions}
          contactID={contact._id}
          setCartSubmission={setCartSubmission}
          close={closeCancelCartSubmission}
        />
      </CustomModal>

      <Drawer
        anchor="right"
        open={shoppingCartOpen}
        onClose={handleToggleShoppingCart}
      >
        {renderShoppingCart()}
      </Drawer>
    </>
  );
};

export default ShoppingCartButton;
