import axios from "axios";

export const getOrders = async ({
  appUserLevel,
  contactID,
  outstandingOrders,
}) => {
  if (appUserLevel?.includes("Super User"))
    return axios
      .get(`/api/order/get-all?outstandingOrders=${outstandingOrders}`)
      .then((res) => res.data.orders ?? []);
  else if (appUserLevel?.includes("Supplier") && contactID)
    return axios
      .get(
        `/api/order/get-all-by-supplier?contactID=${contactID}&outstandingOrders=${outstandingOrders}`
      )
      .then((res) => res.data.orders);
  else if (appUserLevel?.includes("Shipper") && contactID)
    return axios
      .get(
        `/api/order/get-all-by-shipper?contactID=${contactID}&outstandingOrders=${outstandingOrders}`
      )
      .then((res) => res.data.orders ?? []);
  else
    return axios
      .get(`/api/order/get-by-contact`, {
        params: {
          contactID,
          outstandingOrders,
        },
      })
      .then((res) => res.data.orders ?? []);
};

export const getOrderByID = async ({ orderID, orderNumber }) => {
  return axios
    .get(`/api/order/get-by-id?orderID=${orderID}&orderNumber=${orderNumber}`)
    .then((res) => res.data.order);
};

export const getOrderNumbers = async ({
  facilityID,
  outstandingOrders,
  supplierID,
}) => {
  if (facilityID) {
    return axios
      .get(
        `/api/order/get-order-numbers?outstandingOrders=${outstandingOrders}&facilityID=${facilityID}`
      )
      .then((res) => res.data.orders);
  } else if (supplierID) {
    return axios
      .get(
        `/api/order/get-order-numbers?&outstandingOrders=${outstandingOrders}&supplierID=${supplierID}`
      )
      .then((res) => res.data.orders);
  } else {
    return [];
  }
};

export const updateOrderStatus = async ({
  orderID,
  orderNumber,
  status,
  cancelationReason,
}) => {
  return axios
    .patch("/api/order/update-status", {
      orderID,
      orderNumber,
      status,
      cancelationReason,
    })
    .then((res) => res.data.order);
};

export const completeOrder = async (body) => {
  return axios.patch("/api/order/complete-order", body);
};

export const reportOrder = async ({ orderID, status, reportedHistory }) => {
  return axios
    .patch("/api/order/report-order", {
      orderID,
      status,
      reportedHistory,
    })
    .then((res) => res.data.order);
};

export const getInvoicePDF = async ({ orderID, orderNumber }) => {
  return axios
    .get(
      `/api/order/get-invoice-pdf?orderID=${orderID}&orderNumber=${orderNumber}`,
      {
        responseType: "blob", // Set the response type to 'blob'
      }
    )
    .then((res) => res.data);
};

export const getPOPDF = async ({ orderID }) => {
  return axios
    .get(`/api/order/get-po-pdf?orderID=${orderID}`, {
      responseType: "blob", // Set the response type to 'blob'
    })
    .then((res) => res.data);
};

export const getSupplierInvoicePDF = async ({ orderID, orderNumber }) => {
  return axios
    .get(
      `/api/order/get-supplier-invoice-pdf?orderID=${orderID}&orderNumber=${orderNumber}`,
      {
        responseType: "blob", // Set the response type to 'blob'
      }
    )
    .then((res) => res.data);
};

export const getMonthlyBalanceStatementPDF = async ({ facilityID, date }) => {
  return axios
    .get(
      `/api/order/get-monthly-balance-statement-pdf?facilityID=${facilityID}&date=${date}`,
      {
        responseType: "blob", // Set the response type to 'blob'
      }
    )
    .then((res) => res.data);
};
