import React, { useState } from "react";
import { CustomModal } from "../../sharedComponents";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";
import useAdjustServiceContractRequest from "../../../customHooks/serviceContractOrder/useAdjustServiceContractRequest";
import useApproveServiceContractRequest from "../../../customHooks/serviceContractOrder/useApproveServiceContractRequest";
import useDenyServiceContractRequest from "../../../customHooks/serviceContractOrder/useDenyServiceContractRequest";
import useFacilityContacts from "../../../customHooks/facility/useFacilityContacts";
import { getFacilityRecipients } from "../../../sharedFunctions/getFacilityRecipients";
import { getContactOptionLabel } from "../../../sharedFunctions/labels";

const ServiceContractActionButtons = ({ close, serviceContract }) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const { facilityContacts } = useFacilityContacts(
    serviceContract?.facility?._id
  );

  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [adjustModalOpen, setAdjustModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [customerPONumber, setCustomerPONumber] = useState("");
  const [notes, setNotes] = useState("");
  const [recipient, setRecipient] = useState(null);

  const approveServiceContractRequest = useApproveServiceContractRequest();
  const adjustServiceContractRequest = useAdjustServiceContractRequest();
  const denyServiceContractRequest = useDenyServiceContractRequest();

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setNotes("");
    setApproveModalOpen(false);
    setAdjustModalOpen(false);
    setCancelModalOpen(false);
  };

  const onApproveRequest = async () => {
    const body = {
      serviceContract: {
        _id: serviceContract._id,
        customerPONumber,
        recipient,
      },
    };

    await approveServiceContractRequest.mutateAsync(body);

    closeModal();
    close();
  };

  const onAdjustRequest = async () => {
    const body = {
      serviceContract: { _id: serviceContract._id },
      notes,
    };

    await adjustServiceContractRequest.mutateAsync(body);

    closeModal();
    close();
  };

  const onDenyRequest = async () => {
    const body = {
      serviceContract: { _id: serviceContract._id },
      notes,
    };

    await denyServiceContractRequest.mutateAsync(body);

    closeModal();
    close();
  };

  return (
    <Grid container spacing={2} item xs={12}>
      {serviceContract?.orders[0]?.status === "Awaiting Approval" && (
        <Grid
          item
          xs={
            serviceContract?.orders[0]?.status === "Awaiting Approval" ? 4 : 6
          }
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setApproveModalOpen(true)}
            fullWidth
          >
            Approve
          </Button>
        </Grid>
      )}
      {(((appUserLevel.includes("Super User") ||
        appUserLevel.includes("Facility")) &&
        serviceContract?.orders[0]?.status === "Adjustment Requested") ||
        serviceContract?.orders[0]?.status === "Awaiting Approval") && (
        <Grid
          item
          xs={
            serviceContract?.orders[0]?.status === "Awaiting Approval" ? 4 : 6
          }
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => setAdjustModalOpen(true)}
            fullWidth
          >
            Request Adjustment
          </Button>
        </Grid>
      )}

      {(serviceContract?.orders[0]?.status === "Adjustment Requested" ||
        serviceContract?.orders[0]?.status === "Awaiting Approval") && (
        <Grid
          item
          xs={
            serviceContract?.orders[0]?.status === "Awaiting Approval" ? 4 : 6
          }
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => setCancelModalOpen(true)}
            fullWidth
          >
            Deny
          </Button>
        </Grid>
      )}

      <CustomModal
        open={approveModalOpen}
        close={closeModal}
        title="Service Agreement"
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Autocomplete
              options={getFacilityRecipients(facilityContacts)}
              getOptionLabel={getContactOptionLabel}
              value={recipient}
              isOptionEqualToValue={(option, value) => {
                if (!value.length) return [];
                return option._id === value._id;
              }}
              onChange={(e, value) => {
                setRecipient(value);
              }}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="Order Recipient"
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Customer PO #"
              value={customerPONumber}
              onChange={(e) => setCustomerPONumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              By clicking "Approve" you are confirming that the service
              agreement is accurate and you agree to the terms.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onApproveRequest();
              }}
              fullWidth
              disabled={!recipient}
            >
              Approve
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <CustomModal
        open={adjustModalOpen}
        close={closeModal}
        title="Service Agreement"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please describe the adjustments needed for the service agreement.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={onAdjustRequest}
              fullWidth
              disabled={!notes}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <CustomModal
        open={cancelModalOpen}
        close={closeModal}
        title="Service Agreement"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please enter a reason for denying the service agreement.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={onDenyRequest}
              fullWidth
              disabled={!notes}
            >
              Deny
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  );
};

export default ServiceContractActionButtons;
