import { useCallback } from "react";
import { getAddressLabel } from "../../sharedFunctions/labels";
import { checkIfValidAddress } from "../../sharedFunctions/checkIfValidAddress";

const useGetClosestWarehouse = () => {
  const getClosestWarehouse = useCallback(
    async ({ facilityAddressObject, warehouses, supplierProductDetail }) => {
      if (!warehouses?.length) return;
      const service = new window.google.maps.DistanceMatrixService();

      const facilityAddress = facilityAddressObject?.hasCoordinates
        ? new window.google.maps.LatLng(
            facilityAddressObject.coordinates.split(", ")[0],
            facilityAddressObject.coordinates.split(", ")[1]
          )
        : getAddressLabel(facilityAddressObject);
      const facilityAddresses = [];

      let validWarehouses;

      if (!supplierProductDetail) {
        validWarehouses = warehouses.filter((warehouse) =>
          checkIfValidAddress(warehouse?.contact?.address)
        );
      } else {
        validWarehouses = warehouses.filter(
          (warehouse) =>
            checkIfValidAddress(warehouse?.contact?.address) &&
            supplierProductDetail.warehouses.some(
              (spdWarehouse) => spdWarehouse._id === warehouse._id
            )
        );
      }
      const warehouseAddresses = validWarehouses.map((warehouse) => {
        facilityAddresses.push(facilityAddress);
        const warehouseAddress = warehouse.contact.address.hasCoordinates
          ? new window.google.maps.LatLng(
              warehouse.contact.address.coordinates.split(", ")[0],
              warehouse.contact.address.coordinates.split(", ")[1]
            )
          : getAddressLabel(warehouse.contact.address);
        return warehouseAddress;
      });

      try {
        const res = await service.getDistanceMatrix({
          origins: facilityAddresses,
          destinations: warehouseAddresses,
          travelMode: "DRIVING",
        });
        const lowestElement = res.rows[0].elements.reduce((prev, curr) =>
          curr.distance?.value < prev.distance?.value ? curr : prev
        );

        const index = res.rows[0].elements.findIndex(
          (element) => element.distance?.value === lowestElement.distance?.value
        );
        return {
          ...warehouses[index],
          distance: lowestElement.distance?.value,
        };
      } catch (error) {
        return warehouses[0];
      }
    },
    []
  );

  return getClosestWarehouse;
};

export default useGetClosestWarehouse;
