import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getContactOptionLabel } from "../../sharedFunctions/labels";
import FormSelect from "../sharedComponents/selectors/FormSelect";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import ImageSection from "../sharedComponents/ImageSection";
import FormSwitch from "../sharedComponents/toggles/FormSwitch";
import useContactManufacturers from "../../customHooks/contacts/useContactManufacturers";
import useUpdateManufacturerProductDetail from "../../customHooks/manufacturerProductDetails/useUpdateManufacturerProductDetail";
import useManufacturerProductDetailsUploadSDSFiles from "../../customHooks/manufacturerProductDetails/useManufacturerProductDetailsUploadSDSFiles";
import useManufacturerProductDetailsUploadPSSFiles from "../../customHooks/manufacturerProductDetails/useManufacturerProductDetailsUploadPSSFiles";
import useManufacturerProductDetailsDeleteFile from "../../customHooks/manufacturerProductDetails/useManufacturerProductDetailsDeleteFile";
import useAddManufacturerProductDetail from "../../customHooks/manufacturerProductDetails/useAddManufacturerProductDetail";
import useDeleteManufacturerProductDetail from "../../customHooks/manufacturerProductDetails/useDeleteManufacturerProductDetail";

const ManufacturerProductDetailsForm = (props) => {
  const { close, editMode, manufacturerProductDetail, product } = props;

  const { manufacturers, isLoading: isLoadingManufacturers } =
    useContactManufacturers();

  const [canStack, setCanStack] = useState(
    manufacturerProductDetail?.canStack ?? false
  );
  console.log();
  const [containerSize, setContainerSize] = useState(
    manufacturerProductDetail?.containerSize ??
      product.type === "Testing and Calibration Materials"
      ? product.containerSize
      : ""
  );
  const [containerSizeUnit, setContainerSizeUnit] = useState(
    manufacturerProductDetail?.containerSizeUnit ??
      product.type === "Testing and Calibration Materials"
      ? product.containerSizeUnit
      : ""
  );
  const [dimensionUnit, setDimensionUnit] = useState(
    manufacturerProductDetail?.dimensionUnit ?? ""
  );
  const [height, setHeight] = useState(manufacturerProductDetail?.height ?? "");
  const [length, setLength] = useState(manufacturerProductDetail?.length ?? "");
  const [lifeSpan, setLifeSpan] = useState(
    manufacturerProductDetail?.lifeSpan ?? ""
  );
  const [manufacturer, setManufacturer] = useState(
    manufacturerProductDetail?.manufacturer ?? ""
  );
  const [partNumber, setPartNumber] = useState(
    manufacturerProductDetail?.partNumber ?? ""
  );
  const [PSSFiles, setPSSFiles] = useState(
    manufacturerProductDetail?.PSSFiles ?? []
  );
  const [SDSFiles, setSDSFiles] = useState(
    manufacturerProductDetail?.SDSFiles ?? []
  );
  const [shape, setShape] = useState(manufacturerProductDetail?.shape ?? "");
  const [weight, setWeight] = useState(manufacturerProductDetail?.weight ?? "");
  const [weightUnit, setWeightUnit] = useState(
    manufacturerProductDetail?.weightUnit ?? ""
  );
  const [width, setWidth] = useState(manufacturerProductDetail?.width ?? "");
  const [saving, setSaving] = useState(false);
  const [valid, setValid] = useState(true);

  const manufacturerProductDetailsUploadSDSFiles =
    useManufacturerProductDetailsUploadSDSFiles();
  const manufacturerProductDetailsUploadPSSFiles =
    useManufacturerProductDetailsUploadPSSFiles();
  const manufacturerProductDetailsDeleteFile =
    useManufacturerProductDetailsDeleteFile();
  const addManufacturerProductDetail = useAddManufacturerProductDetail();
  const updateManufacturerProductDetail = useUpdateManufacturerProductDetail();
  const deleteManufacturerProductDetail = useDeleteManufacturerProductDetail();

  useEffect(() => {
    if (!manufacturer) return setValid(false);
    setValid(true);
  }, [manufacturer, setValid]);

  const onSubmit = async () => {
    setSaving(true);
    const body = {
      canStack,
      containerSize,
      containerSizeUnit,
      dimensionUnit,
      height,
      length,
      lifeSpan,
      manufacturer: manufacturer._id,
      partNumber,
      product: product._id,
      shape,
      weight,
      weightUnit,
      width,
    };

    if (manufacturerProductDetail) {
      body._id = manufacturerProductDetail._id;
      await updateManufacturerProductDetail.mutateAsync({
        manufacturerProductDetail: body,
      });
    } else {
      await addManufacturerProductDetail.mutateAsync({
        manufacturerProductDetail: body,
      });
    }

    close();
    setSaving(false);
  };

  const onDelete = async () => {
    setSaving(true);

    if (manufacturerProductDetail?.isDeleted) {
      await updateManufacturerProductDetail.mutateAsync({
        manufacturerProductDetail: {
          _id: manufacturerProductDetail._id,
          isDeleted: false,
        },
      });
    } else {
      await deleteManufacturerProductDetail.mutateAsync({
        manufacturerProductDetailID: manufacturerProductDetail._id,
      });
    }

    close();
    setSaving(false);
  };

  const uploadSDSFiles = async (event) => {
    if (!event?.target?.files) return;

    const files = event.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i], files[i].name);
    }
    formData.append(
      "manufacturerProductDetailID",
      manufacturerProductDetail._id
    );

    await manufacturerProductDetailsUploadSDSFiles.mutateAsync(formData);
  };

  const deleteSDSFile = async (fileID) => {
    if (manufacturerProductDetail) {
      const tempSDSFiles = SDSFiles.filter((file) => file.fileID !== fileID);

      setSDSFiles(tempSDSFiles);
    }

    await manufacturerProductDetailsDeleteFile.mutateAsync({
      fileID,
      manufacturerProductDetailID: manufacturerProductDetail._id,
    });
  };

  const uploadPSSFiles = async (event) => {
    if (!event?.target?.files) return;

    const files = event.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i], files[i].name);
    }
    formData.append(
      "manufacturerProductDetailID",
      manufacturerProductDetail._id
    );

    await manufacturerProductDetailsUploadPSSFiles.mutateAsync(formData);
  };

  const deletePSSFile = async (fileID) => {
    if (manufacturerProductDetail) {
      const tempPSSFiles = PSSFiles.filter((file) => file.fileID !== fileID);

      setPSSFiles(tempPSSFiles);
    }

    await manufacturerProductDetailsDeleteFile.mutateAsync({
      fileID,
      manufacturerProductDetailID: manufacturerProductDetail._id,
    });
  };

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={!editMode || !!manufacturer?._id}
          options={manufacturers}
          getOptionLabel={getContactOptionLabel}
          isOptionEqualToValue={(option, value) => {
            if (!value.length) return [];
            return option?._id === value?._id;
          }}
          value={manufacturer}
          onChange={(e, value) => {
            setManufacturer(value);
          }}
          loading={isLoadingManufacturers}
          renderInput={(params) => (
            <TextField {...params} label="Manufacturer" required />
          )}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          autoComplete="off"
          id="partNumber"
          label="Part Number"
          variant="outlined"
          value={partNumber}
          onChange={(e) => {
            setPartNumber(e.target.value);
          }}
          disabled={!editMode}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <DecimalTextField
          editMode={editMode}
          value={lifeSpan}
          onChange={(e) => {
            setLifeSpan(e.target.value);
          }}
          label={"Life Span (years)"}
          required={false}
          allowMinus={false}
        />
      </Grid>
      {(product.type === "Chemical" ||
        product.type === "Testing and Calibration Materials" ||
        product.type === "Filter") && (
        <>
          <Grid item xs={6} md={3}>
            <TextField
              autoComplete="off"
              id="containerSize"
              label="Container Size"
              variant="outlined"
              value={containerSize}
              onChange={(e) => {
                setContainerSize(e.target.value);
              }}
              disabled={
                !editMode ||
                product.type === "Testing and Calibration Materials"
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormSelect
              editMode={editMode}
              value={containerSizeUnit}
              onChange={(e) => {
                setContainerSizeUnit(e.target.value);
              }}
              menuItems={
                product.type === "Filter"
                  ? ["PK"]
                  : product.type === "Testing and Calibration Materials"
                  ? [product.containerSizeUnit]
                  : ["kg", "lb", "ml", "L", "gal", "PK"]
              }
              label="Container Unit"
              required={false}
              disabled={product.type === "Testing and Calibration Materials"}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Typography>Dimensions</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="dimensionUnitLabel">Dimension Unit</InputLabel>
            <Select
              value={dimensionUnit}
              onChange={(e) => {
                setDimensionUnit(e.target.value);
              }}
              labelId="dimensionUnitLabel"
              label="Dimension Unit"
              disabled={!editMode}
            >
              <MenuItem value={"cm"}>cm</MenuItem>
              <MenuItem value={"inch"}>inch</MenuItem>
              <MenuItem value={"feet"}>feet</MenuItem>
              <MenuItem value={"m3"}>m3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="weightUnitLabel">Weight Unit</InputLabel>

            <Select
              value={weightUnit}
              onChange={(e) => {
                setWeightUnit(e.target.value);
              }}
              labelId="weightUnitLabel"
              label="Weight Unit"
              disabled={!editMode}
            >
              <MenuItem value={"lbs"}>lbs</MenuItem>
              <MenuItem value={"kg"}>kg</MenuItem>
              <MenuItem value={"tonne"}>tonne</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormSelect
            editMode={editMode}
            value={shape}
            onChange={(e) => {
              setShape(e.target.value);
            }}
            menuItems={["Circle"]}
            label="Shape"
            required={false}
          />
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4} md={3}>
            <DecimalTextField
              label={"Length"}
              value={length}
              editMode={editMode}
              onChange={(e) => {
                setLength(e.target.value);
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {dimensionUnit}
                  </InputAdornment>
                ),
              }}
              allowMinus={false}
              required={false}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <DecimalTextField
              label={"Width"}
              value={width}
              editMode={editMode}
              onChange={(e) => {
                setWidth(e.target.value);
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {dimensionUnit}
                  </InputAdornment>
                ),
              }}
              allowMinus={false}
              required={false}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <DecimalTextField
              label={"Height"}
              value={height}
              editMode={editMode}
              onChange={(e) => {
                setHeight(e.target.value);
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {dimensionUnit}
                  </InputAdornment>
                ),
              }}
              allowMinus={false}
              required={false}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <DecimalTextField
              label={"Weight"}
              value={weight}
              editMode={editMode}
              onChange={(e) => {
                setWeight(e.target.value);
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">{weightUnit}</InputAdornment>
                ),
              }}
              allowMinus={false}
              required={false}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <FormSwitch
              label={"Can Stack"}
              checked={canStack}
              onChange={(e) => {
                setCanStack(e.target.checked);
              }}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
      </Grid>

      {manufacturerProductDetail && (
        <>
          <Grid item xs={12}>
            <ImageSection
              itemID={manufacturerProductDetail?._id}
              editMode={editMode}
              files={SDSFiles}
              uploadFileTypes={"application/pdf"}
              uploadFunction={uploadSDSFiles}
              deleteFunction={deleteSDSFile}
              title={"Safety Data Sheet (SDS)"}
            />
          </Grid>

          <Grid item xs={12}>
            <ImageSection
              itemID={manufacturerProductDetail?._id}
              editMode={editMode}
              files={PSSFiles}
              uploadFileTypes={"application/pdf"}
              uploadFunction={uploadPSSFiles}
              deleteFunction={deletePSSFile}
              title={"Product Spec Sheet"}
            />
          </Grid>
        </>
      )}

      {editMode && manufacturerProductDetail && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color={manufacturerProductDetail?.isDeleted ? "primary" : "error"}
            fullWidth
            onClick={onDelete}
            disabled={!valid || !editMode || saving}
          >
            {manufacturerProductDetail?.isDeleted
              ? saving
                ? "Updating"
                : "Enable"
              : saving
              ? "Updating"
              : "Disable"}
          </Button>
        </Grid>
      )}
      {editMode && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
            disabled={!valid || !editMode || saving}
          >
            {manufacturerProductDetail
              ? saving
                ? "Updating"
                : "Update"
              : saving
              ? "Saving"
              : "Save"}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ManufacturerProductDetailsForm;
